<script>
export default {
  props: {
    unique: Boolean,
    attKey: String,
    required: Boolean,
    label: String
  }
};
</script>
<template>
  <div class="d-flex">
    <span v-if="label">
      {{ label }}
    </span>
    <span v-else>
      {{
        String(attKey)
          .replaceAll("_", " ")
          .toUpperCase()
      }}
    </span>

    <span v-if="!!required" class="red--text mx-1"><strong>*</strong></span>
    <div v-if="Object.keys($slots).length > 0">
      <slot></slot>
    </div>
  </div>
</template>
