
import { Component, Vue } from "vue-property-decorator";
import PartnersBar from "@/components/Partner/PartnersBar.vue";
import PartnersList from "@/components/Partner/PartnersList.vue"; // @ is an alias to /src

@Component({
  components: {
    PartnersBar,
    PartnersList
  }
})
export default class Home extends Vue {}
