import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Region } from "../../model";

@Module
export default class Regions extends VuexModule {
  regions = Array<Region>();

  @Mutation
  setRegions(regions: [Region]) {
    this.regions = regions;
  }

  @Mutation
  updateRegion(region: Region) {
    if (this.regions.find(x => x.id === region.id)) {
      this.regions = _.map(this.regions, function(a) {
        return a.id === region.id ? region : a;
      });
    } else {
      this.regions.push(region);
    }
  }

  @Mutation
  removeRegion(region: Region) {
    _.remove(this.regions, {
      id: region.id
    });
  }

  @Action
  fetchRegions() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "region/")
      .then((resp: AxiosResponse) => {
        console.log("fetchRegions");
        this.context.commit("setRegions", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchRegion(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "region/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchRegion: " + id);
        this.context.commit("updateDevice", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveRegion(region: Region) {
    region = _.pickBy(region, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Region;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "region/";
    if (region.id) {
      method = "put";
      url += region.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: region })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveRegion");
          if (!region.id) {
            region.id = resp.data.id;
          }
          this.context.dispatch("fetchRegion", region.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployRegion(region: Region) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "region/" + region.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployRegion");
          // eslint-disable-next-line @typescript-eslint/camelcase
          region.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchRegion", region.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteRegion(region: Region) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "region/" +
            region.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteRegion");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeRegion", region);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getRegions() {
    return this.regions.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getRegion() {
    return (id: number) => {
      const ret = this.regions.find(x => x.id === id) as Region;
      return ret ? ret : new Region();
    };
  }
}
