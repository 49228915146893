import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Vlan } from "../../model";

@Module
export default class Vlans extends VuexModule {
  vlans = Array<Vlan>();

  @Mutation
  setVlans(vlans: [Vlan]) {
    this.vlans = vlans;
  }

  @Mutation
  updateVlan(vlan: Vlan) {
    if (this.vlans.find(x => x.id === vlan.id)) {
      this.vlans = _.map(this.vlans, function(a) {
        return a.id === vlan.id ? vlan : a;
      });
    } else {
      this.vlans.push(vlan);
    }
  }

  @Mutation
  removeVlan(vlan: Vlan) {
    _.remove(this.vlans, {
      id: vlan.id
    });
  }

  @Action
  fetchVlans() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "vlan/")
      .then((resp: AxiosResponse) => {
        console.log("fetchVlans");
        this.context.commit("setVlans", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchVlan(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "vlan/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchVlan: " + id);
        this.context.commit("updateVlan", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveVlan(vlan: Vlan) {
    vlan = _.pickBy(vlan, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Vlan;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "vlan/";
    if (vlan.id) {
      method = "put";
      url += vlan.id;
    }

    return new Promise((resolve, reject) => {
      axios({ method, url, data: vlan })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveVlan");
          if (!vlan.id) {
            vlan.id = resp.data.id;
          }
          this.context.dispatch("fetchVlan", vlan.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployVlan(vlan: Vlan) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "vlan/" + vlan.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployVlan");
          // eslint-disable-next-line @typescript-eslint/camelcase
          vlan.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchVlan", vlan.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteVlan(vlan: Vlan) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "vlan/" + vlan.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteVlan");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeVlan", vlan);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getVlans() {
    return this.vlans.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getVlan() {
    return (id: number) => {
      const ret = this.vlans.find(x => x.id === id) as Vlan;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.site_id) ret.site_id = ret.site.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.headend_id) ret.headend_id = ret.headend.id;
      return ret ? ret : new Vlan();
    };
  }
}
