import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Ipsec } from "../../model";

@Module
export default class Ipsecs extends VuexModule {
  ipsecs = Array<Ipsec>();

  @Mutation
  setIpsecs(ipsecs: [Ipsec]) {
    this.ipsecs = ipsecs;
  }

  @Mutation
  updateIpsec(ipsec: Ipsec) {
    if (this.ipsecs.find(x => x.id === ipsec.id)) {
      this.ipsecs = _.map(this.ipsecs, function(a) {
        return a.id === ipsec.id ? ipsec : a;
      });
    } else {
      this.ipsecs.push(ipsec);
    }
  }

  @Mutation
  removeIpsec(ipsec: Ipsec) {
    _.remove(this.ipsecs, {
      id: ipsec.id
    });
  }

  @Action
  fetchIpsecs() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "ipsec/")
      .then((resp: AxiosResponse) => {
        console.log("fetchIpsecs");
        this.context.commit("setIpsecs", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchIpsec(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "ipsec/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchIpsec: " + id);
        this.context.commit("updateIpsec", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveIpsec(ipsec: Ipsec) {
    ipsec = _.pickBy(ipsec, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Ipsec;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "ipsec/";
    if (ipsec.id) {
      method = "put";
      url += ipsec.id;
    }

    return new Promise((resolve, reject) => {
      axios({ method, url, data: ipsec })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveIpsec");
          if (!ipsec.id) {
            ipsec.id = resp.data.id;
          }
          this.context.dispatch("fetchIpsec", ipsec.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployIpsec(ipsec: Ipsec) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "ipsec/" + ipsec.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployIpsec");
          // eslint-disable-next-line @typescript-eslint/camelcase
          ipsec.netbox_ids = resp.data.netbox_ids;
          this.context.dispatch("fetchIpsec", ipsec.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteIpsec(ipsec: Ipsec) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "ipsec/" + ipsec.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteIpsec");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeIpsec", ipsec);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getIpsecs() {
    return this.ipsecs.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getIpsec() {
    return (id: number) => {
      const ret = this.ipsecs.find(x => x.id === id) as Ipsec;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.vrf_id) ret.vrf_id = ret.vrf?.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.site_id) ret.site_id = ret.site?.id;
      return ret ? ret : new Ipsec();
    };
  }
}
