import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Customer } from "../../model";

@Module
export default class Customers extends VuexModule {
  customers = Array<Customer>();

  @Mutation
  setCustomers(customers: [Customer]) {
    this.customers = customers;
  }

  @Mutation
  updateCustomer(customer: Customer) {
    if (this.customers.find(x => x.id === customer.id)) {
      this.customers = _.map(this.customers, function(a) {
        return a.id === customer.id ? customer : a;
      });
    } else {
      this.customers.push(customer);
    }
  }

  @Mutation
  removeCustomer(customer: Customer) {
    _.remove(this.customers, {
      id: customer.id
    });
  }

  @Action
  fetchCustomers() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "customer/")
      .then((resp: AxiosResponse) => {
        console.log("fetchCustomers");
        this.context.commit("setCustomers", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchCustomer(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "customer/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchCustomer: " + id);
        this.context.commit("updateCustomer", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveCustomer(customer: Customer) {
    customer = _.pickBy(customer, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Customer;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "customer/";
    if (customer.id) {
      method = "put";
      url += customer.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: customer })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveCustomer");
          if (!customer.id) {
            customer.id = resp.data.id;
          }
          this.context.dispatch("fetchCustomer", customer.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployCustomer(customer: Customer) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_PRESTO_API + "customer/" + customer.id + "/deploy"
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployCustomer");
          // eslint-disable-next-line @typescript-eslint/camelcase
          customer.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchCustomer", customer.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteCustomer(customer: Customer) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "customer/" +
            customer.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteCustomer");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeCustomer", customer);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getCustomers() {
    return this.customers.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getCustomer() {
    return (id: number) => {
      const ret = this.customers.find(x => x.id === id) as Customer;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.partner_id) ret.partner_id = ret.partner.id;
      return ret ? ret : new Customer();
    };
  }
}
