
import { Component, Vue } from "vue-property-decorator";
import TailsBar from "@/components/Tail/TailsBar.vue";
import TailsList from "@/components/Tail/TailsList.vue"; // @ is an alias to /src

@Component({
  components: {
    TailsBar,
    TailsList
  }
})
export default class Home extends Vue {}
