
import { Component, Vue } from "vue-property-decorator";
import Headend from "@/components/Headend/Headend.vue";

@Component({
  components: {
    Headend
  }
})
export default class Home extends Vue {}
