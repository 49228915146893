<template>
  <DataTable
    :items="getDevices"
    sort-by="name"
    :headers="headers"
    :itemLinkBase="itemLinkBase"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTable.vue";

export default {
  computed: {
    ...mapGetters(["getDevices"])
  },
  data: () => ({
    itemLinkBase: "/device/",
    headers: [
      { text: "ID", value: "id", width: "80px" },
      {
        text: "Device Name",
        align: "start",
        value: "name"
      },

      { text: "Partner", value: "partner.name" },
      { text: "Netbox ID", value: "netbox_id", width: "150px" }
    ]
  }),
  components: {
    DataTable
  }
};
</script>

<style></style>
