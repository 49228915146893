<template>
  <v-layout fluid v-resize="onResize">
    <v-card style="width: 100%">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          autofocus
        ></v-text-field>
      </v-card-title>
      <v-data-table
        id="dataTable"
        :height="tableHeight"
        :headers="headers"
        :items="filteredItems"
        :items-per-page="itemsPerPage"
        :footer-props="footerProps"
        :sort-by="sortBy"
        @click:row="gotoItem"
        class="elevation-1"
        style="width: 100%; cursor: pointer; "
      >
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="itemLinkBase" color="secondary" @click="editItem(item)">
            <v-icon class="mr-2">mdi-pencil</v-icon> View
          </v-btn>
          <router-link
            class="mx-2"
            :to="`${itemLinkBase}${item.id}/config`"
            v-if="hasConfig"
          >
            <v-btn color="success">
              <v-icon class="">mdi-cog</v-icon>
            </v-btn>
          </router-link>
          <slot></slot>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import router from "@/router";

export default {
  props: ["items", "sortBy", "headers", "itemLinkBase", "hasConfig"],
  created: function() {
    this.onResize();
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.tableHeight = this.windowSize.y - 64 - 80 - 59; // App Bar, Search Bar, Footer
      this.itemsPerPage = parseInt((this.tableHeight - 48) / 48);
    },
    gotoItem(item) {
      if (this.itemLinkBase) {
        router.push(this.itemLinkBase + item.id );
      }
    },
    editItem(item) {
      if (this.itemLinkBase) {
        router.push(this.itemLinkBase + item.id );
      }
    },
    searchItems(search, item) {
      if (search.length >= 2) {
        return this.recursiveSearch(search, item);
      }
      return true;
    },
    recursiveSearch(search, item) {
      if (typeof item === "object") {
        for (const key in item) {
          if (this.recursiveSearch(search, item[key])) return true;
        }
        return false;
      }
      if (typeof item === "string") {
        if (item.toUpperCase().includes(search.toUpperCase())) {
          return true;
        }
      }
      if (typeof item === "number" && parseInt(search)) {
        if (item.toString().includes(search)) {
          return true;
        }
      }
    }
  },
  data: () => ({
    search: "",
    itemsPerPage: 0,
    windowSize: {
      x: 0,
      y: 0
    },
    tableHeight: 0
  }),
  computed: {
    footerProps() {
      return {
        "items-per-page-options": [10, 20, 50, 100, this.itemsPerPage, -1]
      };
    },
    filteredItems() {
      return this.items.filter(o => this.searchItems(this.search, o));
    }
  }
};
</script>

<style></style>
