// This is a series of static functions and constantrs that adjust the Forms and their attributes
// Basically anything thats we can't / shouldnt include in the Swagger schema
// things like visibility rules, overrides for

// this is a series of functions to run, keyed by path name on top level, and attribute name on bottom
// if the path and attribute is found on an input, we tie the given function to the v-show attribute
// hopefully Vue is reactive enough to figure this out

/* eslint-disable camelcase */
// prettier-ignore


// a series of functions keys by path, then field name
// these are used in forms to control the visibility of entire fields
// and assume the function is run as the main form item as an argument
// this lets us perofrm visibility calculations based on ALL the fields in a form
// which is common in form building: showing nad hiding fields based on choices elsewhere
const visibility = {
  device_interface: {
    dhcp_dns1: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_dns2: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_high: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_low: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_option: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_option_string: item => !!item.dhcp && item.interface_type == "lan",
    dhcp_option_type: item => !!item.dhcp && item.interface_type == "lan",
    dhcp: item => item.interface_type == "lan",
    ip_address: item => item.interface_type == "lan",
    ipsec_interface_id: item => item.interface_type == "ipsec",
    pppoe_id: item => item.interface_type == "pppoe",
    pppoe_underlying: item => item.interface_type == "pppoe",
    priority: item =>
      item.interface_type == "static_service" || item.interface_type == "tail",
    static_service_id: item => item.interface_type == "static_service",
    subscriber_service_id: item => item.interface_type == "subscriber_service",
    tail_id: item => item.interface_type == "tail",
    vrrp: item => item.interface_type == "lan",
    vrrp_address: item => item.interface_type == "lan",
    vrrp_group: item => item.interface_type == "lan"
  }
};

// each function here is passed the full item being edited,
// in order to set a prefix/suffix from other values in the form
const prefixes = {
  site: {
    name: item =>
      !!item.customer &&
      !!item.customer.slug &&
      item.customer.slug.toUpperCase() + " ",
    slug: item =>
      !!item.customer &&
      !!item.customer.slug &&
      item.customer.slug.toUpperCase() + "-"
  },
  nebs_vlan: {
    name: item =>
      !!item.site && !!item.site.slug && item.site.slug.toUpperCase() + ""
  },
  ee_vlan: {
    name: item =>
      !!item.site && !!item.site.slug && item.site.slug.toUpperCase() + ""
  },
  vlan: {
    name: item =>
      !!item.site && !!item.site.slug && item.site.slug.toUpperCase() + ""
  },
  device: {
    name: item =>
      !!item.site && !!item.site.slug && item.site.slug.toUpperCase() + ""
  },
  static_service: {
    name: item => {
      const parent_vlan =
        (!!item.ee_vlan && item.ee_vlan) ||
        (!!item.nebs_vlan && item.nebs_vlan);
      if (!!parent_vlan && !!parent_vlan.name) {
        return String(parent_vlan.name).toUpperCase() + "";
      }
      return false;
    }
  },
  subscriber_service: {
    name: item => {
      const parent_vlan =
        (!!item.ee_vlan && item.ee_vlan) ||
        (!!item.nebs_vlan && item.nebs_vlan);
      if (!!parent_vlan && !!parent_vlan.name) {
        return String(parent_vlan.name).toUpperCase() + "";
      }
      return false;
    }
  },
  ipsec: {
    name: item =>
      !!item.site && !!item.site.slug && item.site.slug.toUpperCase() + ""
  }
  // device_interface: {
  //   name: item => !!item.parent_interface && item.parent_interface
  // }
};
const suffixes = {
  device: {
    name: item => !!item.backup && "-BACKUP"
  }
  // device_interface: {
  //   name: item => !!String(item.vlan_id) && String(item.vlan_id)
  // }
};

// minimums and maximums are just applied straight to the form: no calculations required
// these are min/max INCLUSIVE and are set on numerical input fields
const maximums = {
  subscriber_service: {
    speed: 1000
  }
};
const minimums = {
  subscriber_service: {
    speed: 25
  }
};

// specify a path and key here to force a given field to be readonly and not editable
// this is in addition to readonly fields from the main schema
const readOnlies = {
  site: {}
};

// this is a list of fields that are generated by other fields in the form, and not directly editable
// this operates simiar to visibility, where this is a list of functions keyed by path then field name 
// that are supplied the whole form item. The result is set as the field value on the form
// 
const calculatedFields = {
  device_interface: {
    name: item => item.parent_interface && (item.vlan_id || item.vlan_id === 0) ? item.parent_interface + '.' + item.vlan_id : ''
  }
};

// this is a manual list of paths that have specific child models which should only ever be edited as a child
// this means we pretty much only can get there from an existing parent
const directChildren = [
  {
    path: "static_service",
    child: "static_service_prefix"
  },
  {
    path: "subscriber_service",
    child: "subscriber_service_prefix"
  },
  {
    path: "device",
    child: "device_interface"
  },
  {
    path: "ipsec_interface",
    child: "ipsec_prefix"
  },
  {
    path: "tail",
    child: "tail_prefix"
  }
];

const models = {
  site: { icon: "mdi-office-building-marker-outline", color: "#90CAF9" }, // Light Blue
  customer: { icon: "mdi-account-group-outline", color: "#81C784" }, // Light Green
  device: { icon: "mdi-router-network", color: "#FFCC80" }, // Light Orange
  ee_vlan: { icon: "mdi-pipe", color: "#F48FB1" }, // Light Pink
  nebs_vlan: { icon: "mdi-pipe", color: "#CE93D8" }, // Light Purple
  vlan: { icon: "mdi-pipe", color: "#CE93D8" },
  static_service: { icon: "mdi-link", color: "#80DEEA" }, // Light Cyan
  subscriber_service: { icon: "mdi-sync", color: "#FFAB91" }, // Light Deep Orange
  ipsec: { icon: "mdi-lock", color: "#B0BEC5" }, // Light Blue Grey
  poi: { icon: "mdi-map-marker-radius", color: "#A1887F" }, // Light Brown
  nni: { icon: "mdi-connection", color: "#B0BEC5" }, // Light Grey
  vrf: { icon: "mdi-cloud-outline", color: "#9FA8DA" }, // Light Indigo
  cvc: { icon: "mdi-connection", color: "#FFD54F" } // Light Amber
};

export default {
  visibility,
  prefixes,
  suffixes,
  readOnlies,
  directChildren,
  maximums,
  minimums,
  models,
  calculatedFields
};
