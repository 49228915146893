<script>
import axios from "axios";
import store from "./simpleStore";

export default {
  mounted() {
    this.getSchemas();
  },
  data() {
    return {
      retries: 0,
      timeout: false
    };
  },
  methods: {
    async getSchemas() {
      // first check store for a copy of schemas
      if (store && store.requests && store.responseSchemas) {
        return;
      }
      // this is a global indicator that we are fetching the specs
      if (store.loadingSchema) {
        this.timeout = setTimeout(() => this.getSchemas(), 5000); // run every five seconds until the request completes
        return;
      }
      store.loadingSchema = true;
      const response = await axios.get(
        `${process.env.VUE_APP_PRESTO_CORE_API}specs`
      );
      // now filter for only request schemas
      // response schemas include primary keys,
      // relationships etc that we do not need
      const schemas =
        (response &&
          response.data &&
          response.data.components &&
          response.data.components.schemas &&
          Object.fromEntries(
            Object.entries(response.data.components.schemas).sort()
          )) ||
        {};
      const requestString = "_request";
      const responseString = "_response";
      const requests =
        schemas &&
        Object.keys(schemas)
          .filter(key => String(key).includes(requestString))
          .reduce((obj, key) => {
            const newKey = String(key).replace(requestString, "");
            obj[newKey] = schemas[key];
            return obj;
          }, {});
      const responses =
        schemas &&
        Object.keys(schemas)
          .filter(key => String(key).includes(responseString))
          .reduce((obj, key) => {
            const newKey = String(key).replace(responseString, "");
            obj[newKey] = schemas[key];
            return obj;
          }, {});
      // console.log(filtered, schemas)
      store.schemas = requests || {};
      store.responseSchemas = responses || {};
      console.log("OPEN API Schemas: ", store.schemas);
      store.loadingSchema = false;
      if (store.schemas && Object.keys) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      } else {
        this.retries++;
      }
    }
  },
  computed: {
    hasSchemas() {
      return store.schemas && Object.keys(store.schemas).length > 0;
    },
    schemas() {
      return this.hasSchemas && store.schemas;
    },
    responseSchemas() {
      return this.hasSchemas && store.responseSchemas;
    }
  }
};
</script>

<template>
  <div>
    <v-overlay v-if="!hasSchemas">
      <v-card class="pa-4">
        <v-progress-linear
          striped
          stream
          rounded
          indeterminate
        ></v-progress-linear>
      </v-card>
    </v-overlay>
    <slot v-else :schemas="schemas" :responseSchemas="responseSchemas"></slot>
  </div>
</template>
