<template>
  <DataTable
    :items="getIpsecs"
    sort-by="name"
    :headers="headers"
    :itemLinkBase="itemLinkBase"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTable.vue";

export default {
  computed: {
    ...mapGetters(["getIpsecs"])
  },
  data: () => ({
    itemLinkBase: "/ipsec/",
    headers: [
      { text: "ID", value: "id", width: "80px" },
      {
        text: "Ipsec Name",
        align: "start",
        value: "name"
      },

      { text: "Customer", value: "site.customer.name" }
    ]
  }),
  components: {
    DataTable
  }
};
</script>

<style></style>
