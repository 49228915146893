
import { Component, Vue } from "vue-property-decorator";
import DevicesBar from "@/components/Device/DevicesBar.vue";
import DevicesList from "@/components/Device/DevicesList.vue"; // @ is an alias to /src

@Component({
  components: {
    DevicesBar,
    DevicesList
  }
})
export default class Home extends Vue {}
