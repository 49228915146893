<template>
  <DataTable
    :items="getTails"
    sort-by="name"
    :headers="headers"
    :itemLinkBase="itemLinkBase"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTable.vue";

export default {
  computed: {
    ...mapGetters(["getTails"])
  },
  data: () => ({
    itemLinkBase: "/tail/",
    headers: [
      { text: "ID", value: "id" },
      {
        text: "Tail Name",
        align: "start",
        value: "name"
      },
      { text: "Speed (Mbps)", value: "speed" },
      { text: "Vlan ID", value: "vlan.vid" },
      { text: "Customer", value: "vlan.site.customer.name" },
      { text: "Netbox ID", value: "netbox_id", width: "150px" }
    ]
  }),
  components: {
    DataTable
  }
};
</script>

<style></style>
