
import { Component, Vue } from "vue-property-decorator";
import SitesBar from "@/components/Site/SitesBar.vue";
import SitesList from "@/components/Site/SitesList.vue"; // @ is an alias to /src

@Component({
  components: {
    SitesBar,
    SitesList
  }
})
export default class Home extends Vue {}
