
import Vue from "vue";
import router from "@/router";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  Vrf,
  Customer,
  PushConfigRequest,
  PushConfigResponse
} from "../../model";
import Alert from "@/components/Alert.vue";
import PushConfig from "@/components/PushConfig.vue";

export default Vue.extend({
  name: "Vrf",
  components: {
    Alert,
    PushConfig
  },
  computed: {
    ...mapGetters(["getVrf", "isAdmin", "getAlert", "getVrfs", "getCustomers"]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    vrfId(): number {
      return +this.$route.params.id;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: function() {
    this.model = { ...this.getVrf(this.vrfId) };
  },
  watch: {
    getVrfs() {
      this.model = { ...this.getVrf(this.vrfId) };
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getVrf(this.vrfId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Vrf()
  }),
  methods: {
    submit() {
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Vrf();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("saveVrf", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            this.$store
              .dispatch("deployVrf", reduced)
              .then(() => {
                this.isLoading = false;
                this.submitButton = "success";

                if (this.isNew) {
                  router.push({
                    path: "/vrf/" + reduced.id,
                    query: { submitButton: this.submitButton }
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.isLoading = false;
                this.submitButton = "error";
              });
          })
          .catch(err => {
            console.log(err);
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deleteVrf() {
      if (confirm("Are you sure you want to delete this VRF?")) {
        if (this.model.id) {
          const reduced = new Vrf();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deleteVrf", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/vrfs"
              });
            })
            .catch(err => {
              console.log(err);
              this.isLoading = false;
            });
        }
      }
    },
    async pushConfig() {
      await (this.$refs.pushConfigDialog as Vue & {
        show: (opts: PushConfigRequest) => PushConfigResponse;
      }).show({
        objectType: "vrf",
        objectId: this.model.id,
        test: true
      });
    },
    nameRules() {
      const rules = [];

      rules.push((v: string) => !!v || "Name is required");

      rules.push(() => !this.isDuplicate("name") || "Name is not unique");

      return rules;
    },
    isDuplicate(keyName: string) {
      const x = this.getVrfs.filter((item: Vrf) => {
        return item.id != this.vrfId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    },
    customerChange(e: number) {
      this.model.name = this.getCustomers.find(
        (customer: Customer) => customer.id === e
      ).name;
    }
  }
});
