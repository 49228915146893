
import { Component, Vue } from "vue-property-decorator";
import Vlan from "@/components/Vlan/Vlan.vue";

@Component({
  components: {
    Vlan
  }
})
export default class Home extends Vue {}
