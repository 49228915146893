<template>
    <SearchBar/>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
export default {
  components: { SearchBar },
  data() {
    return {
      loading: false
    }
  }
}
</script>
