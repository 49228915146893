<template>
  <withItems :path="path" v-slot="{ items }" @done="onDone">
    <v-autocomplete
      :custom-filter="customFilter"
      :items="getSelectItems(items)"
      outlined
      item-text="text"
      item-value="value"
      v-model="internalValue"
      :rules="rules"
      autocomplete="null"
    >
      <template v-slot:label>
        <Label
          :required="required"
          :attKey="attKey"
          :label="label || `Select a ${path.toUpperCase()}`"
        >
          <div v-if="filters && Object.keys(filters).length > 0">
            <span class="mx-2">|</span> Filters Applied
          </div>
        </Label>
      </template>
      <v-icon slot="prepend"> mdi-link-plus </v-icon>
    </v-autocomplete>
  </withItems>
</template>

<script>
import withItems from "../withItems.vue";
import Label from "./Label.vue";
import store from "../simpleStore.js";

export default {
  props: ["value", "path", "attKey", "filters", "rules", "label", "required"],
  components: {
    withItems,
    Label
  },
  data() {
    return {
      internalValue: null,
      items: {}, // a holder for the full items
    };
  },
  computed: {
    pageMeta() {
      return (
        store &&
        store.pageMeta &&
        Object.keys(store.pageMeta).length &&
        store.pageMeta
      );
    }
  },
  created(){
    if ( this.value ) {
      this.internalValue = this.value;
    }
  },
  watch: {
    value( val ) {
      this.internalValue = val;
    },
    internalValue( val) {
      this.$emit("input", val); // this syncs internValue with the v-model on this component
      this.emitEvent();
    }
  },
  methods: {
    onDone(items) {
      // this function fires when the withItems finishes fetching data
      // we save the item data on the component for future uses
      this.items = items;
      this.emitEvent();
    },
    emitEvent() {
      // this is called on internalValue change AND items change
      // this ensures that the event fires when the internal value AND items is set
      this.$nextTick( () => {
        const current = this.internalValue;
        const foundItem =
          this.items && current in this.items && this.items[current];
        if (foundItem) {
          //console.log("emitting setlistselectitem");
          const payload = {
            path: this.path,
            key: this.attKey,
            item: foundItem
          };
          this.$emit("setlistselectitem", payload);
          this.$parent.$emit("setlistselectitem", payload);
          this.$parent.$parent.$emit("setlistselectitem", payload);
          this.$parent.$parent.$parent.$emit("setlistselectitem", payload);
        }
      })

    },
    customFilter(item, queryText, itemText) {
      const searchables = [
        item.slug ? item.slug.toLowerCase() : "",
        item.repr ? item.repr.toLowerCase() : "",
        item.name ? item.name.toLowerCase() : "",
        itemText
      ];
      const searchText = queryText.toLowerCase();

      return searchables.some(text => text.indexOf(searchText) > -1);
    },
    getSelectItems(items) {
      /*
      filters = { itemKey: suppliedValue }
      */
      if (!items) {
        return [];
      }
      let filters = this.filters || {};
      if (this.stepFilters) {
        filters = { ...filters, ...this.stepFilters };
      }
      if (Object.keys(filters).length) {
        console.log("filtering by ", filters);
      }
      const a = Object.keys(items)
        .filter(k => {
          const target = items[k];
          for (const key in filters) {
            if (
              !!(key in target) &&
              !!filters[key] &&
              String(target[key])
                .trim()
                .toLowerCase() !=
                String(filters[key])
                  .trim()
                  .toLowerCase()
            ) {
              return false;
            }
          }
          return true;
        })
        .map(key => ({
          text: `${items[key].repr} #${key}`,
          value: parseInt(key)
        }));
      // if the field is not required, add a null value options to clear the field
      const sorted = a.sort((first, second) => first.value < second.value);
      sorted.unshift({ text: "None", value: null });
      // console.log("sorted items");
      return sorted;
    }
  }
};
</script>
