<template>
  <DataTable
    :items="getPppoes"
    sort-by="name"
    :headers="headers"
    :itemLinkBase="itemLinkBase"
  />
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTable.vue";

export default {
  computed: {
    ...mapGetters(["getPppoes"])
  },
  data: () => ({
    itemLinkBase: "/pppoe/",
    headers: [
      { text: "ID", value: "id", width: "80px" },
      {
        text: "PPPoE Name",
        align: "start",
        value: "name"
      },

      { text: "Username", value: "username" },
      { text: "Customer", value: "site.customer.name" },
      { text: "Radius ID", value: "radius_id" },
      { text: "Netbox ID", value: "netbox_id", width: "150px" }
    ]
  }),
  components: {
    DataTable
  }
};
</script>

<style></style>
