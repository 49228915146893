
import { Component, Vue } from "vue-property-decorator";
import Tail from "@/components/Tail/Tail.vue";

@Component({
  components: {
    Tail
  }
})
export default class Home extends Vue {}
