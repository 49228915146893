<script>
import withSchemas from "../withSchemas.vue";
import withItems from "../withItems.vue";
import Step from "./_Step.vue";
import Form from "../Forms/Form.vue";
import store from "../simpleStore.js";

export default {
  components: {
    withSchemas,
    withItems,
    Step,
    Form
  },
  data() {
    return {
      steps: {
        device: {
          path: "device",
          id: false,
          instructions:
            "Create a Device Service or select one to add new Interfaces."
        }
      }
    };
  }
};
</script>

<template>
  <withSchemas>
    <!-- Generic Header for all paths -->
    <v-toolbar>
      <div class="">
        <h4>Create a Device</h4>
      </div>
    </v-toolbar>
    <v-container>
      <Step
        :step="steps['device']"
        @done="data => (steps['device'].data = data)"
        index="1"
      />

      <v-row v-if="steps['device'].id">
        <v-col>
          <!--  Create an Item -->
          <v-alert type="info" outlined>
            <strong>Step 2:</strong>
            Create as many Interfaces as you need using the Child Selection on
            the Right
          </v-alert>
          <withItems
            path="device"
            :id="String(steps['device'].id)"
            v-slot="{ items, item }"
          >
            <Form
              path="device"
              :id="String(steps['device'].id)"
              :columns="2"
              :item="item"
            >
            </Form>
          </withItems>
        </v-col>
      </v-row>
    </v-container>
  </withSchemas>
</template>
