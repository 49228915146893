<template>
  <div>
      <v-toolbar>
        <v-text-field
          v-model="search"
          label="Search Presto"
          solo-filled
          clearable
          hide-details
          single-line
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle"
          @keyup="debounceInput"
          :loading="isLoading"
        />
      </v-toolbar>
    <v-container>
        <div class="w-100 pa-2 my-2"> 
          <div v-if="! results ">

          </div>
          <div v-else-if="emptyResults">
            <v-alert type="warning">
              No Results Returned. Try something more generic, like an address or company name. 
            </v-alert>
          </div>
          <div v-else>
            <v-alert type="success">
              Found {{ resultCount }} results across {{  modelCount  }} objects. 
            </v-alert>
            <template v-for="group in [ models, notModels(results) ]">
              <div v-for="( model, path ) in group" :key="path" class="pa-2">
                  <div v-for="(line, index) in results[path]" :key="index" >
                    
                    <v-btn variant="outline" :href="`/v2/${path}/${line.id}/view`" block class="mb-2 text-left" 
                      :title="`Open ${path} #${line.id}`">
                      <v-icon class="pa-2" :style="[{'color': model.color}]">{{ model.icon }}</v-icon>
                      <div class="text-uppercase mr-4" style="width: 25%">
                        {{ path }}
                      </div>
                      {{  line.repr }}
                      <div class="w-4 mx-2 ml-auto">
                        #{{ line.id }}
                      </div>
                      <v-icon right class="">mdi-open-in-new</v-icon>
                    </v-btn>
                  </div>
                </div>

            </template>
          </div>
        </div>
      </v-container>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import _ from 'lodash';
  import adjustments from "./Core/Forms/adjustments.js";
  const { models } = adjustments;
  export default {
    data() {
      return {
        search: '',
        results: false,
        isLoading: false,
        defaultColor: '#D0D0D0',
        defaultIcon: 'mdi-magnify',
        models
      };
    },
    computed: {
      emptyResults(){
        return ! this.results || ! Object.keys(this.results).some( key => this.results[key].length )
      },
      resultCount(){
        return Object.keys(this.results).map( key => this.results[key].length ).reduce((partialSum, a) => partialSum + a, 0);
      },
      modelCount(){
        return Object.keys(this.results).filter( key => this.results[key].length ).length
      },
      
    },
    watch: {
      search(newValue) {
        const window = document.defaultView
        const queryString = newValue ? '?query=' + encodeURIComponent(`${newValue}`) : ''
        window.history.replaceState(  
          {},
          null,
          this.$route.path + queryString
        )
        // clear the results if the query string is empty
        if ( ! newValue ) {
          this.results = false
        }
      },
    },
    mounted() {
      this.search = this.$route.query.query || '';
      if (this.search) {
        this.fetchResults(this.search);
      }
    },
    methods: {
      notModels( r ) {
        const m = this.models
        return Object.keys(r)
          .filter( p => r[p].length) // only get results with data 
          .filter( p => !( p in m )) // and only models that are not already displayed 
          .reduce( ( o, p ) => ({ [p]: { 'icon': this.defaultIcon, 'color': this.defaultIcon },  ...o }), {} ) // return a structure similar to models
      },
      fetchResults(query) {
        if (!query) {
          this.results = [];
          this.isLoading = false;
          return;
        }
  
        this.isLoading = true;
  
        axios
          .get(`${process.env.VUE_APP_PRESTO_CORE_API}search`, { params: { query } })
          .then((response) => {
            this.results = response.data;
            console.log(response.data)
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      },
      debounceInput: _.debounce(function () {
        // only search for strings with more than 2 characters
        if ( this.search && this.search.length > 2)
        this.fetchResults(this.search);
      }, 500),
    },
  };
  </script>