
import Vue from "vue";
import router from "@/router";
import _ from "lodash";
import { mapGetters } from "vuex";
import { Headend } from "../../model";
import Alert from "@/components/Alert.vue";

export default Vue.extend({
  name: "Headend",
  components: {
    Alert
  },
  computed: {
    ...mapGetters([
      "getHeadend",
      "isAdmin",
      "getAlert",
      "getHeadends",
      "getSites"
    ]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    headendId(): number {
      return +this.$route.params.id;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: async function() {
    if (!this.getHeadends.length) await this.$store.dispatch("fetchHeadends");
    this.model = { ...this.getHeadend(this.headendId) };

    if (!this.model.site_id && this.model.site?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.site_id = this.model.site.id;
    }
  },
  watch: {
    getHeadends() {
      this.model = { ...this.getHeadend(this.headendId) };

      if (!this.model.site_id && this.model.site?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.site_id = this.model.site.id;
      }
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getHeadend(this.headendId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Headend()
  }),
  methods: {
    submit() {
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Headend();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("saveHeadend", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            this.isLoading = false;
            this.submitButton = "success";

            if (this.isNew) {
              router.push({
                path: "/headend/" + reduced.id,
                query: { submitButton: this.submitButton }
              });
            }
          })
          .catch(() => {
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deleteHeadend() {
      if (confirm("Are you sure you want to delete this headend?")) {
        if (this.model.id) {
          const reduced = new Headend();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deleteHeadend", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/headends"
              });
            })
            .catch(err => {
              console.log(err);
              this.isLoading = false;
            });
        }
      }
    },
    isDuplicate(keyName: string) {
      const x = this.getHeadends.filter((item: Headend) => {
        return item.id != this.headendId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    }
  }
});
