
import Vue from "vue";
import router from "@/router";
import _ from "lodash";
import { mapGetters } from "vuex";
import { Partner } from "../../model";
import Alert from "@/components/Alert.vue";

export default Vue.extend({
  name: "Partner",
  components: {
    Alert
  },
  computed: {
    ...mapGetters(["getPartner", "isAdmin", "getAlert", "getPartners"]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    partnerId(): number {
      return +this.$route.params.id;
    },
    updateSlug() {
      if (
        this.slugOld.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() ==
        this.model.slug
      ) {
        return true;
      }
      return false;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: function() {
    this.model = { ...this.getPartner(this.partnerId) };
  },
  watch: {
    getPartners() {
      this.model = { ...this.getPartner(this.partnerId) };
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getPartner(this.partnerId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    slugOld: "",
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Partner()
  }),
  methods: {
    submit() {
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Partner();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("savePartner", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            this.$store.commit("updatePartner", reduced);

            this.$store
              .dispatch("deployPartner", reduced)
              .then(() => {
                this.isLoading = false;
                this.submitButton = "success";

                if (this.isNew) {
                  router.push({
                    path: "/partner/" + reduced.id,
                    query: { submitButton: this.submitButton }
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.isLoading = false;
                this.submitButton = "error";
              });
          })
          .catch(err => {
            console.log(err);
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deletePartner() {
      if (confirm("Are you sure you want to delete this Partner?")) {
        if (this.model.id) {
          const reduced = new Partner();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deletePartner", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/partners"
              });
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      }
    },
    slugRules() {
      const rules = [];

      rules.push((v: string) => !!v || "Slug is required");

      rules.push(
        (v: string) =>
          /^[a-zA-Z0-9]*$/.test(v) ||
          "Slug must only contain alphanumeric characters"
      );

      rules.push(
        (v: string) => /^[A-Z0-9]*$/.test(v) || "Slug must be upper case"
      );

      rules.push(
        (v: string) => v.length >= 3 || "Slug must be minimum of 3 characters"
      );
      rules.push(() => !this.isDuplicate("slug") || "Slug is not unique");

      return rules;
    },
    nameRules() {
      const rules = [];

      rules.push((v: string) => !!v || "Name is required");

      rules.push(() => !this.isDuplicate("name") || "Name is not unique");

      return rules;
    },
    isDuplicate(keyName: string) {
      const x = this.getPartners.filter((item: Partner) => {
        return item.id != this.partnerId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    },
    nameChange(e: string) {
      if (this.updateSlug) {
        this.model.slug = e.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
      }
      this.slugOld = this.model.name;
    },
    slugChange(e: string) {
      this.model.slug = e.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    }
  }
});
