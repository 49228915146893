<script>
import withItems from "./withItems.vue";
import itemEdit from "./ItemEdit.vue";

const props = {
  parentPath: {
    type: String,
    required: true
  },
  parentId: {
    type: String,
    required: false
  },
  path: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: false
  }
};

export default {
  props,
  components: {
    withItems,
    itemEdit
  },
  data() {
    return {
      error: false,
      formIsValid: false,
      isNew: false,
      flicker: 0,
      configPaths: ["device", "static_service", "subscriber_service", "ipsec"] // TODO: modve this to adjustments.js
    };
  },
  computed: {
    listUrl() {
      return `/v2/${this.path}/`;
    }
  },
  methods: {
    hasConfig() {
      return this.path && !!this.configPaths.includes(this.path);
    },
  },
  beforeMount() {
    this.isNew = !!this.id && this.id == "new";
    console.log("Parent Page loaded", this.isNew);
  }
};
</script>

<template>
  <withItems
    :path="parentPath"
    :id="parentId || ''"
    v-slot="{ item: parentItem }"
  >
    <itemEdit :path="path" :id="id" :parents="{ [parentPath]: parentItem }">
      <template v-slot:before_form>
        <v-alert type="success" class="mt-4">
          <div class="d-flex">
            <h4 class="text-uppercase">
              {{ isNew ? "Adding" : "Editing" }} as child of
              {{ parentPath }} #{{ parentId }}
            </h4>
            <router-link class="ml-auto" :to="`/v2/${parentPath}/${parentId}`">
              <v-btn color="secondary">
                <v-icon class="mr-4 ">mdi-arrow-left</v-icon>
                Back to Parent: {{ parentItem.repr }}
              </v-btn>
            </router-link>
            <router-link
              class="mx-2"
              :to="`/v2/${parentPath}/${parentId}/${path}/new`"
            >
              <v-btn color="primary">
                <v-icon class="mr-4 ">mdi-plus</v-icon>
                Add Another
              </v-btn>
            </router-link>
          </div>
        </v-alert>
      </template>
    </itemEdit>
  </withItems>
</template>
