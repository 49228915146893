
import Vue from "vue";
import router from "@/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  AddPrefixDialog,
  Pppoe,
  PppoePrefix,
  PushConfigRequest,
  PushConfigResponse,
  Site,
  Vrf
} from "../../model";
import AddPrefix from "@/components/AddPrefix.vue";
import Alert from "@/components/Alert.vue";
import PushConfig from "@/components/PushConfig.vue";

export default Vue.extend({
  name: "Pppoe",
  components: {
    Alert,
    AddPrefix,
    PushConfig
  },
  computed: {
    ...mapGetters([
      "getPppoe",
      "isAdmin",
      "getAlert",
      "getPppoes",
      "getCustomer",
      "getCustomers",
      "getSites",
      "getVrfs"
    ]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    pppoeId(): number {
      return +this.$route.params.id;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: async function() {
    this.model = { ...this.getPppoe(this.pppoeId) };

    this.filterVrfs();
    this.filterSites();
    this.getPppoePrefixes();

    if (!this.model.site_id && this.model.site?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.site_id = this.model.site.id;
    }

    if (!this.model.vrf_id && this.model.vrf?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.vrf_id = this.model.vrf.id;
    }
  },
  watch: {
    getPppoes() {
      this.model = { ...this.getPppoe(this.pppoeId) };

      if (!this.model.site_id && this.model.site?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.site_id = this.model.site.id;
      }

      if (!this.model.vrf_id && this.model.vrf?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.vrf_id = this.model.vrf.id;
      }

      this.filterVrfs();
      this.filterSites();
      this.getPppoePrefixes();
    },
    getSites() {
      this.filterVrfs();
      this.filterSites();
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getPppoe(this.pppoeId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Pppoe(),
    vrfs: [] as Vrf[],
    sites: [] as Site[],
    pppoePrefixes: [] as PppoePrefix[],
    providers: [
      { id: "aapt", name: "AAPT" },
      { id: "aussie", name: "Aussie Broadband" }
    ],
    serviceTypes: [
      { id: "fttb", name: "FTTB" },
      { id: "fttc", name: "FTTC" },
      { id: "fttn", name: "FTTN" },
      { id: "fttp", name: "FTTP" },
      { id: "hfc", name: "HFC" },
      { id: "nwas", name: "NWAS (Wireless)" }
    ],
    prefixTypes: [
      { id: "public_pppoe", name: "Public" },
      { id: "private_pppoe", name: "Private" }
    ]
  }),
  methods: {
    submit() {
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Pppoe();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("savePppoe", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            this.$store
              .dispatch("deployPppoe", reduced)
              .then(() => {
                this.isLoading = false;
                this.submitButton = "success";

                if (this.isNew) {
                  router.push({
                    path: "/pppoe/" + reduced.id,
                    query: { submitButton: this.submitButton }
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.isLoading = false;
                this.submitButton = "error";
              });
          })
          .catch(err => {
            console.log(err);
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deletePppoe() {
      if (confirm("Are you sure you want to delete this PPPoE?")) {
        if (this.model.id) {
          const reduced = new Pppoe();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deletePppoe", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/pppoes"
              });
            })
            .catch(err => {
              console.log(err);
              this.isLoading = false;
            });
        }
      }
    },
    getPppoePrefixes() {
      if (this.model.id && this.model.netbox_id) {
        axios
          .get(
            process.env.VUE_APP_PRESTO_API +
              "pppoe/" +
              this.model.id +
              "/prefix"
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((resp: AxiosResponse<any>) => {
            console.log("fetchPppoePrefixes");
            this.pppoePrefixes = resp.data as PppoePrefix[];
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    async addPppoePrefix() {
      const prefix = await (this.$refs.addPrefixDialog as Vue & {
        show: (opts: AddPrefixDialog) => PppoePrefix;
      }).show({ route: true, disableRoute: true });

      if (prefix && this.model.id && this.model.netbox_id) {
        const method = "post";
        const url =
          process.env.VUE_APP_PRESTO_API + "pppoe/" + this.model.id + "/prefix";

        axios({ method, url, data: prefix })
          .then(() => {
            console.log("addPppoePrefix");
            this.getPppoePrefixes();
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    deletePppoePrefix(prefixId: number) {
      if (confirm("Are you sure you want to delete this prefix?")) {
        console.log("deletePppoePrefix", prefixId);

        axios
          .delete(
            process.env.VUE_APP_PRESTO_API +
              "pppoe/" +
              this.pppoeId +
              "/prefix?netbox=true&prefix_id=" +
              prefixId
          )
          .then(() => {
            this.pppoePrefixes = this.pppoePrefixes.filter(
              x => x.id !== prefixId
            );
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    async pushConfig() {
      await (this.$refs.pushConfigDialog as Vue & {
        show: (opts: PushConfigRequest) => PushConfigResponse;
      }).show({
        objectType: "pppoe",
        objectId: this.model.id,
        test: true
      });
    },
    nameRules() {
      const rules = [];

      rules.push(
        (v: string) =>
          /^[a-zA-Z0-9/-]*$/.test(v) ||
          "Name must only contain alphanumeric characters"
      );

      rules.push(
        (v: string) => /^[A-Z0-9/-]*$/.test(v) || "Name must be upper case"
      );

      rules.push((v: string) => !!v || "Name is required");

      rules.push(() => !this.isDuplicate("name") || "Name is not unique");

      return rules;
    },
    isDuplicate(keyName: string) {
      const x = this.getPppoes.filter((item: Pppoe) => {
        return item.id != this.pppoeId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    },
    customerChange() {
      this.filterVrfs();
      this.filterSites();
    },
    filterVrfs() {
      this.vrfs = this.getVrfs;
      const customerId = this.model.site.customer.id;
      if (customerId) {
        this.vrfs = this.getVrfs.filter(
          (vrf: Vrf) => vrf.customer?.id === customerId
        );
      }
    },
    filterSites() {
      this.sites = this.getSites;
      const customerId = this.model.site.customer.id;
      if (customerId) {
        this.sites = this.getSites.filter(
          (site: Site) => site.customer?.id === customerId
        );
      }
    },
    siteChange(e: number) {
      this.model.name = this.getSites.find((site: Site) => site.id === e).name;
    }
  }
});
