export interface Partner {
  id: number | null;
  uuid: string | null;
  name: string;
  slug: string;
  netbox_id: number | null;
}

export interface Customer {
  id: number | null;
  uuid: string | null;
  name: string;
  slug: string;
  description: string;
  netbox_id: number | null;
  partner: Partner;
  partner_id: number | null;
}

export interface Vrf {
  id: number | null;
  uuid: string | null;
  name: string;
  netbox_id: number | null;
  description: string;
  customer: Customer;
  customer_id: number | null;
}

export interface Region {
  id: number | null;
  uuid: string | null;
  name: string;
  slug: string;
  netbox_id: number | null;
}

export interface Site {
  id: number | null;
  uuid: string | null;
  name: string;
  slug: string;
  facility: string;
  physical_address: string;
  shipping_address: string;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  comments: string;
  asn: number | null;
  netbox_id: number | null;
  region: Region;
  region_id: number | null;
  customer: Customer;
  customer_id: number | null;
}

export interface Headend {
  id: number | null;
  uuid: string | null;
  name: string;
  service_id: string;
  device_netbox_id: number | null;
  interface_netbox_id: number | null;
  comments: string;
  site_id: number | null;
  site: Site;
  utilisation: number | null;
  speed: number;
  provider: string;
  svlan_id: number;
}

export interface Vlan {
  id: number | null;
  uuid: string | null;
  name: string;
  vid: number | null;
  description: string;
  netbox_id: number | null;
  headend_id: number | null;
  headend: Headend;
  site_id: number | null;
  site: Site;
  customer_id: number | null;
  customer: Customer;
}

export interface Tail {
  id: number | null;
  uuid: string | null;
  name: string;
  service_id: string;
  netbox_id: number | null;
  speed: number | null;
  vlan_id: number | null;
  vlan: Vlan;
  vrf_id: number | null;
  vrf: Vrf;
}

export interface TailPrefix {
  id: number | null;
  uuid: string | null;
  netbox_id: number | null;
  tail_id: number | null;
  tail: Tail;
}

export interface TailPrefixCreate {
  prefix_type: string;
  prefix_length: number | null;
  route: boolean;
}

export interface Pppoe {
  id: number | null;
  name: string;
  username: string;
  password: string;
  service_type: string;
  provider: string;
  netbox_id: number | null;
  radius_id: number | null;
  site_id: number | null;
  site: Site;
  vrf_id: number | null;
  vrf: Vrf;
  prefix_type: string;
}

export interface PppoePrefix {
  id: number | null;
  uuid: string | null;
  prefix: string;
  route: boolean;
  netbox_id: number | null;
  pppoe_id: number | null;
  pppoe: Pppoe;
}

export interface PppoePrefixCreate {
  prefix_type: string;
  prefix_length: number | null;
  route: boolean;
}

export interface Ipsec {
  id: number | null;
  name: string;
  psk: string;
  netbox_ids: IpsecNetboxIds[];
  site_id: number | null;
  site: Site;
  vrf_id: number | null;
  vrf: Vrf;
}

export interface IpsecNetboxIds {
  ipsec_id: number | null;
  netbox_id: number | null;
  ipsec_router_id: number | null;
  ipsec_router: IpsecRouter;
}

export interface IpsecPrefix {
  id: number | null;
  uuid: string | null;
  netbox_id: number | null;
  prefix: string | null;
  ipsec_id: number | null;
  ipsec: Ipsec;
  ipsec_router: IpsecRouter;
  ipsec_router_id: number | null;
}

export interface IpsecPrefixCreate {
  prefix_type: string;
  prefix_length: number | null;
}

export interface IpsecRouter {
  id: number | null;
  uuid: string | null;
  name: string;
  device_netbox_id: number | null;
  st0_interface_netbox_id: number | null;
  site_id: number | null;
  site: Site;
}

export interface DeviceType {
  id: number | null;
  manufacturer: string;
  device_type: string;
  device_type_netbox_id: number | null;
  platform_netbox_id: number | null;
  usable_interfaces: string;
  pppoe_interface: string;
}

export interface DeviceInterface {
  id: number | null;
  uuid: string | null;
  name: string;
  description: string;
  netbox_id: number | null;
  device_id: number | null;
  device: Device;
  interface_type: string;
  ip_address: string;
  vlan_id: number;
  pppoe_id: number | null;
  pppoe: Pppoe;
  pppoe_underlying: string;
  tail_id: number | null;
  tail: Tail;
  vrrp: boolean;
  vrrp_address: string;
  vrrp_group: number | null;
  dhcp: boolean;
  dhcp_option: number | null;
  dhcp_option_type: string;
  dhcp_option_string: string;
  dhcp_dns1: string;
  dhcp_dns2: string;
  dhcp_low: string;
  dhcp_high: string;
}

export interface Device {
  id: number | null;
  uuid: string | null;
  name: string;
  site: Site;
  site_id: number | null;
  serial: string;
  backup: boolean;
  nat: boolean;
  netbox_id: number | null;
  ipsec_id: number | null;
  ipsec: Ipsec;
  device_type_id: number | null;
  device_type: DeviceType;
}

export interface LoginResponse {
  token: string;
  username: string;
  level: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface Alert {
  title: string;
  text: string;
  color: string;
}

export interface AddPrefixDialog {
  hideRoute?: boolean;
  disableRoute?: boolean;
  route?: boolean;
}

export interface AddInterfaceDialog {
  deviceInterface: DeviceInterface;
  tails: Tail[];
  pppoes: Pppoe[];
  deviceType: DeviceType;
  isAdmin: boolean;
}

export interface PushConfigRequest {
  objectType: string;
  objectId: number;
  test: boolean;
}

export interface PushConfigResponse {
  device_name: string;
  loaded_config: string;
  diff: string;
  already_configured: boolean;
  comment: string;
  result: boolean;
}

export interface PushDeviceConfigRequest {
  device_id: number;
  console_port: number;
  shutdown: boolean;
}

export class Partner implements Partner {
  id: number | undefined = undefined;
  uuid: string | undefined = undefined;
  name = "";
  slug = "";
  netbox_id: number | undefined = undefined;
}

export class Customer implements Customer {
  constructor() {
    this.partner = new Partner();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  slug = "";
  description = "";
  netbox_id: number | null = null;
  partner: Partner;
  partner_id: number | null = null;
}

export class Vrf implements Vrf {
  constructor() {
    this.customer = new Customer();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  netbox_id: number | null = null;
  description = "";
  customer: Customer;
  customer_id: number | null = null;
}

export class Region implements Region {
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  slug = "";
  netbox_id: number | null = null;
}

export class Site implements Site {
  constructor() {
    this.region = new Region();
    this.customer = new Customer();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  slug = "";
  facility = "";
  physical_address = "";
  shipping_address = "";
  contact_name = "";
  contact_phone = "";
  contact_email = "";
  comments = "";
  netbox_id: number | null = null;
  region: Region;
  region_id: number | null = null;
  customer: Customer;
  customer_id: number | null = null;
}

export class Headend implements Headend {
  constructor() {
    this.site = new Site();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  service_id = "";
  device_netbox_id: number | null = null;
  interface_netbox_id: number | null = null;
  comments = "";
  site_id: number | null = null;
  site: Site;
  utilisation: number | null = null;
  speed: number | null = null;
  provider = "";
  svlan_id: number | null = null;
}

export class Vlan implements Vlan {
  constructor() {
    this.site = new Site();
    this.headend = new Headend();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  vid: number | null = null;
  description = "";
  netbox_id: number | null = null;
  headend_id: number | null = null;
  headend: Headend;
  site_id: number | null = null;
  site: Site;
  customer_id: number | null = null;
  customer: Customer;
}

export class Tail implements Tail {
  constructor() {
    this.vlan = new Vlan();
    this.vrf = new Vrf();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  service_id = "";
  netbox_id: number | null = null;
  speed: number | null = null;
  vlan_id: number | null = null;
  vlan: Vlan;
  vrf_id: number | null = null;
  vrf: Vrf;
}

export class TailPrefix implements TailPrefix {
  constructor() {
    this.tail = new Tail();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  netbox_id: number | null = null;
  tail_id: number | null = null;
  tail: Tail;
}

export class TailPrefixCreate implements TailPrefixCreate {
  prefix_type = "";
  prefix_length: number | null = null;
  route: boolean;
}

export class Pppoe implements Pppoe {
  constructor() {
    this.site = new Site();
    this.vrf = new Vrf();
  }
  id: number | null = null;
  name = "";
  username = "";
  password = "";
  service_type = "";
  provider = "";
  netbox_id: number | null = null;
  radius_id: number | null = null;
  site_id: number | null = null;
  site: Site;
  vrf_id: number | null = null;
  vrf: Vrf;
  prefix_type = "";
}

export class PppoePrefix implements PppoePrefix {
  constructor() {
    this.pppoe = new Pppoe();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  prefix = "";
  route: boolean;
  netbox_id: number | null = null;
  pppoe_id: number | null = null;
  pppoe: Pppoe;
}

export class PppoePrefixCreate implements PppoePrefixCreate {
  prefix_type = "";
  prefix_length: number | null = null;
  route: boolean;
}

export class Ipsec implements Ipsec {
  constructor() {
    this.site = new Site();
    this.vrf = new Vrf();
  }
  id: number | null = null;
  name = "";
  psk = "";
  netbox_ids: IpsecNetboxIds[];
  site_id: number | null = null;
  site: Site;
  vrf_id: number | null = null;
  vrf: Vrf;
}

export class IpsecRouter implements IpsecRouter {
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  device_netbox_id: number | null = null;
  st0_interface_netbox_id: number | null = null;
  site_id: number | null = null;
  site: Site;
}

export class IpsecNetboxIds implements IpsecNetboxIds {
  constructor() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.ipsec_router = new IpsecRouter();
  }
  ipsec_id: number | null = null;
  netbox_id: number | null = null;
  ipsec_router_id: number | null = null;
  ipsec_router: IpsecRouter;
}

export class IpsecPrefix implements IpsecPrefix {
  constructor() {
    this.ipsec = new Ipsec();
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.ipsec_router = new IpsecRouter();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  netbox_id: number | null = null;
  prefix: string | undefined = undefined;
  ipsec_id: number | null = null;
  ipsec: Ipsec;
  ipsec_router: IpsecRouter;
  ipsec_router_id: number | null = null;
}

export class IpsecPrefixCreate implements IpsecPrefixCreate {
  prefix_type = "";
  prefix_length: number | null = null;
}

export class DeviceType implements DeviceType {
  id: number | null = null;
  manufacturer = "";
  device_type = "";
  device_type_netbox_id: number | null = null;
  platform_netbox_id: number | null = null;
  usable_interfaces = "";
  pppoe_interface = "";
}

export class Device implements Device {
  constructor() {
    this.site = new Site();
    this.ipsec = new Ipsec();
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.device_type = new DeviceType();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  site: Site;
  site_id: number | null = null;
  serial = "";
  backup: boolean;
  nat: boolean;
  netbox_id: number | null = null;
  ipsec_id: number | null = null;
  ipsec: Ipsec;
  device_type_id: number | null = null;
  device_type: DeviceType;
}

export class DeviceInterface implements DeviceInterface {
  constructor() {
    this.device = new Device();
    this.pppoe = new Pppoe();
    this.tail = new Tail();
  }
  id: number | null = null;
  uuid: string | undefined = undefined;
  name = "";
  description = "";
  netbox_id: number | null = null;
  device_id: number | null = null;
  device: Device;
  interface_type = "";
  ip_address = "";
  vlan_id: number | null = 0;
  pppoe_id: number | null = null;
  pppoe: Pppoe;
  pppoe_underlying = "";
  tail_id: number | null = null;
  tail: Tail;
  vrrp: boolean;
  vrrp_address = "";
  vrrp_group: number | null = null;
  dhcp: boolean;
  dhcp_option: number | null = null;
  dhcp_option_type = "";
  dhcp_option_string = "";
  dhcp_dns1 = "";
  dhcp_dns2 = "";
  dhcp_low = "";
  dhcp_high = "";
}

export class PushConfigResponse implements PushConfigResponse {
  device_name = "";
  loaded_config = "";
  diff = "";
  already_configured = false;
  comment = "";
  result = false;
}

export class PushConfigRequest implements PushConfigRequest {
  objectType = "";
  objectId = 0;
  test = false;
}

export class PushDeviceConfigRequest implements PushDeviceConfigRequest {
  device_id = 0;
  console_port = 0;
  shutdown = false;
}
