<script>
import axios from "axios";
import store from "../simpleStore";
import DynamicInput from "./DynamicInput.vue";

import adjustments from "./adjustments.js";
const { directChildren, models } = adjustments;

const props = {
  path: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  item: {
    type: Object,
    required: true
  },
  parents: {
    type: Object,
    required: false,
    default: () => ({})
  },
  columns: {
    type: Number,
    required: false,
    default: 2 // possible values are 1 and 2
  },
  showMeta: {
    type: Boolean,
    required: false,
    default: false
  }
};

export default {
  name: "Form", // this is here so we can use <Form> recursively
  props,
  components: {
    DynamicInput
  },
  data() {
    return {
      isLoading: true,
      renderDelay: false,
      error: false,
      formIsValid: false,
      isNew: false,
      editing: {},
      directChildren,
      models,
      refreshDefaults: 0,
      showMetaOverride: false,
      showDelete: false
    };
  },
  watch: {
    parents() {
      // now sprinkle in the parent preselections
      if (this.itemsFromParents) {
        for (const key in this.itemsFromParents) {
          const val = this.itemsFromParents[key];
          if (!this.editing[key]) {
            console.log(
              "Assigning Value from parent ABOVE",
              this.path,
              key,
              val
            );
            this.editing[key] = val;
          }
        }
      }
    }
  },
  beforeMount() {
    this.isNew = !this.id;
  },
  mounted() {
    // then we first bring up the Form, create a shadow copy of the item
    // this allows us to detect change, and not mutate a prop passed from above
    if (! this.isNew ) {
      this.editing = Object.assign({}, this.item);
    }

    // if this  is a new form, we have a different issue: the editing fields will not be reactive
    // so we need to go in and populate them all with defaults, based on the schema properties
    // this is mostly to make computed properties work: having these values set by $set 
    // will ensure that Vue can monitor all the keys in the item object 
    // and re-render the calculated fields appropriately when anything changes in the hwole item
    if (this.isNew ) {
      for ( const key in this.schema.properties ) { 
        // set default values for each field, most cases this will be null
        const d = this.schema.properties[key].type == 'string' ? '' : undefined
        this.$set(this.editing, key, ( ( 'default' in this.schema.properties[key]) ? this.schema.properties[key].default  : d ))
      }
    }

    // handle removing prefixes
    if (this.validPrefixes) {
      for (const prefix of this.validPrefixes) {
        if (prefix.result) {
          this.editing[prefix.key] = String(this.editing[prefix.key]).replace(
            prefix.result,
            ""
          );
          console.log(
            "removing prefix ",
            prefix.result,
            this.editing[prefix.key]
          );
        }
      }
    }
    if (this.validSuffixes) {
      for (const suffix of this.validSuffixes) {
        if (suffix.result) {
          this.editing[suffix.key] = String(this.editing[suffix.key]).replace(
            suffix.result,
            ""
          );
          console.log(
            "removing suffix ",
            suffix.result,
            this.editing[suffix.key]
          );
        }
      }
    }

    // now sprinkle in the parent preselections
    if (this.itemsFromParents) {
      for (const key in this.itemsFromParents) {
        const val = this.itemsFromParents[key];
        console.log("Assigning Value from parent selection", key, val);
        this.$set(this.editing, key, val);
      }
    }

    // show the form after all the calculations are done
    this.refreshDefaults++;
    this.isLoading = false;
    console.log("form mounted", this.path, this.id, this.itemsFromParents);
  },
  computed: {
    relationships() {
      return (
        this.responseSchema &&
        this.responseSchema.properties &&
        Object.entries(this.responseSchema.properties)
          .filter(([k, att]) => !!("x-path" in att) && !(att.type == "integer"))
          .map(([k, att]) => ({ key: k, path: att["x-path"] }))
      );
    },
    hasChanged() {
      return (
        this.editing &&
        !!(JSON.stringify(this.editing) != JSON.stringify(this.item))
      );
    },
    schemas() {
      return store.schemas || {};
    },
    schema() {
      const schema =
        store.schemas && store.schemas[this.path]
          ? store.schemas[this.path]
          : false;
      return schema;
    },
    responseSchema() {
      const schema =
        store.responseSchemas && store.responseSchemas[this.path]
          ? store.responseSchemas[this.path]
          : false;
      return schema;
    },
    children() {
      const direct = this.directChild || [];
      const children =
        this.item &&
        Object.entries(this.item)
          .filter(([key, value]) => Array.isArray(value))
          .map(([key, items]) => ({
            key,
            items: items.sort((a, b) => (a.repr > b.repr ? 1 : -1)),
            path: this.getPathFromSchemaProperty(key, true)
          }))
          .map(c => ({ ...c, isDirect: direct.includes(c.path) })) // add a value that call this child out as Direct
          .filter(c => c.path); // filter out children without a valid path ( ie. path is falsey )

      const notEmpty = children.filter(c => c.items.length);
      const empty = children.filter(c => !c.items.length);
      return [...notEmpty, ...empty];
    },
    directChildrenItems() {
      return this.children.filter(child => child.isDirect);
    },
    indirectChildrenItems() {
      return this.children.filter(child => !child.isDirect);
    },
    directChild() {
      // this is a list of all the paths that are children of this one
      // we show and edit link for any chilkdren here that includes this as a parent
      // and also a button to create a new one, also with this is as a parent.
      return (
        this.schemas &&
        this.directChildren &&
        this.directChildren
          .filter(s => {
            if (
              !s.path ||
              !(s.path in this.schemas) ||
              !(s.child in this.schemas)
            ) {
              console.error(
                "directChild path not found",
                this.schemas,
                s.path,
                s.children
              );
              return false;
            }
            return !!(s.path == this.path);
          })
          .map(s => s.child)
      );
    },
    readOnlySchemas() {
      if (!this.schema) return false;
      if (!this.responseSchema) return false;
      const schema = this.schema;
      const filtered = Object.entries(this.responseSchema.properties).filter(
        ([key, att]) => !(key in schema.properties)
      );
      return Object.fromEntries(filtered);
    },
    foreignKeys() {
      return this.generateForeignKeys(this.schema);
    },
    itemsFromParents() {
      // this generates a series of preset IDs for dropdowns based on any parents that may be passed
      // this is merged with the editing item on mount
      const parents = this.parents;
      if (!parents || !Object.keys(parents).length || !this.foreignKeys) {
        return {};
      }
      // now we match the parent objects to fk attributes
      // generating a set of their foreign keys for each based on their path
      // this forms a series of filters for each FK on the form
      const filters = this.foreignKeys
        .filter(
          fk =>
            !!(fk.path in parents) &&
            parents[fk.path] &&
            !!("id" in parents[fk.path])
        )
        .reduce((obj, fk) => ({ [fk.key]: parents[fk.path].id, ...obj }), {});

      return filters;
    },
    defaults() {
      // parse the schema and return a keyed array of default values
      // these are applied to a new form object
      const defaultsss =
        this.schema &&
        Object.entries(this.schema.properties)
          .filter(([key, att]) => !!("default" in att))
          .map(([key, att]) => ({ key, default: att.default }));
      console.log("DEFAULTS: ", defaultsss);
      return defaultsss;
    },
    invisible() {
      const def = this.refreshDefaults; // this is here just to kick the render after mount
      const invis = this.processAdjustments(adjustments.visibility);
      const simple =
        invis &&
        Array.isArray(invis) &&
        invis.filter(i => !i.result).map(i => i.key);
      return simple || [];
    },
    validPrefixes() {
      const def = this.refreshDefaults;
      return this.processAdjustments(adjustments.prefixes);
    },
    validSuffixes() {
      const def = this.refreshDefaults;
      return this.processAdjustments(adjustments.suffixes);
    },
    readOnly() {
      const def = this.refreshDefaults;
      return this.processAdjustments(adjustments.readOnlies);
    }
  },
  methods: {
    processAdjustments(target) {
      // a method for targeting any of the keyed obnjects of functions
      // in adjustments.js, and ruinning the function with the current item
      // returns an array with the key, result and original function
      const editing = JSON.parse(JSON.stringify(this.editing));
      const pathed = !!target && !!(this.path in target) && target[this.path];
      return (
        pathed &&
        Object.entries(pathed).map(([key, func]) => ({
          key,
          result: func(this.editing),
          func: func.toString()
        }))
      );
    },
    validAdjustment(target, key) {
      const found = target && target.find(p => p.key == key);
      return found && found.result && String(found.result).length
        ? found.result
        : false;
    },
    generateForeignKeys(schema) {
      // generate an array of attribtes on this form that are foreign keys
      // helper function for other functions
      const fks =
        schema &&
        schema.properties &&
        Object.entries(schema.properties)
          .filter(([key, att]) => !!("x-path" in att && att.type == "integer"))
          .map(([key, att]) => ({ key, path: att["x-path"] }));

      return fks && fks.length && fks;
    },
    setItem(payload) {
      // a method to receive an event from the ListSelect component
      // when an item is selected, we pass the the item and its path up
      // then we can detect if there is a relationship that matches that path, and set it
      // on the editing model

      // we check to see whether the form isNew to avoid overriding data from the server
      // which is usually more verbose than what we get from list calls
      if (!this.isNew) {
        return;
      }
      const { path, key, item } = payload;
      if (this.relationships && path && item) {
        const found = this.relationships.find(r => r.path == path);
        const isAlreadySet = !!(found.key in this.editing);
        if (found && !isAlreadySet) {
          console.log("setting item", found, item);
          this.editing[found.key] = item;
          this.refreshDefaults++; // this kicks computed properties that include it
        }
      }
    },
    getPathFromSchemaProperty(key, isResponse = false) {
      const target = isResponse ? this.responseSchema : this.schema;
      const prop =
        target && !!(key in target.properties) && target.properties[key];

      if (!prop) return false;

      if ("x-path" in prop) {
        return prop["x-path"];
      }
      if (!!("items" in prop) && !!("x-path" in prop.items)) {
        return prop.items["x-path"];
      }
      return false;
    },
    async createItem(payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/`,
          payload
        );
        console.log(`POST ${this.path} response.data`, response.data);
        if (!response.data) {
          return false;
        }
        const fresh = response.data || {};
        if ("flashes" in fresh) {
          console.log("fresh.flashes", fresh.flashes);
          for (const flash of fresh.flashes) {
            store.addFlash(flash);
          }
        }
        return fresh;
      } catch (error) {
        console.error(error);
        this.error = error.response.data || error || true;
        return false;
      }
    },
    async updateItem(id, payload) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${id}/`,
          payload
        );
        const fresh = response.data || {};
        store.addFlash(["success", "Item Updated"]);
        if ("flashes" in fresh) {
          console.log("fresh.flashes", fresh.flashes);
          for (const flash of fresh.flashes) {
            store.addFlash(flash);
          }
        }
        console.log(`PUT ${this.path} response.data`, fresh);
      } catch (error) {
        console.error(error);
        this.error = error.response.data || error || true;
        store.addFlash(["error", "Error Saving Item"]);
      }
    },
    async deleteItem() {
      try {
        this.isLoading = true;
        const response = await axios.delete(
          `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${this.id}/`
        );
        const fresh = response.data || {};
        if ("flashes" in fresh) {
          console.log("fresh.flashes", fresh.flashes);
          for (const flash of fresh.flashes) {
            store.addFlash(flash);
          }
        }
        store.addFlash(["success", `Item Deleted`]);
      } catch (error) {
        console.error(error);
        this.error = error?.response?.data || error || true;
        store.addFlash(["error", `Error Deleting Item: ${error}`]);
      }
      this.showDelete = false;
      this.isLoading = false;
      this.error = "Item Deleted";
      this.$emit("deleted");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async onSubmit(e) {
      e.preventDefault();
      // always scroll to top of form
      // uid is unique for every instance of a component
      this.$vuetify.goTo(`#form_${this._uid}`, {
        easing: "easeOutQuad",
        offset: 100
      });

      // this should set the formisValid boolean
      this.error = false;
      this.$refs.form.validate();
      if (!this.formIsValid) {
        console.log("Form not valid");
        return;
      }

      this.isLoading = true;
      const payload = Object.assign({}, this.editing);

      // here we delete any of the fields that are undefined
      // these may be undefined due to the setup of the object in the form
      // this is just to clean things up and ensure we are not passing too many fields 
      // form validation should ensure that any fields that need to be here are
      for ( const key in payload ) {
        if ( payload[key] === undefined ) {
          delete payload[key]
        }
      }

      // here we add in any prefixes and suffixes that are shown on frontend
      // but are not part of the editing model data
      // these prefixes will ( hopefully ) be stripped out
      // when the object is loaded
      if (this.validPrefixes) {
        for (const prefix of this.validPrefixes) {
          if (prefix.result && String(prefix.result).length) {
            payload[prefix.key] =
              String(prefix.result) + (payload[prefix.key] || "");
            console.log("adding prefix ", prefix.result, payload[prefix.key]);
          }
        }
      }
      if (this.validSuffixes) {
        for (const suffix of this.validSuffixes) {
          if (suffix.result && String(suffix.result).length) {
            payload[suffix.key] =
              (payload[suffix.key] || "") + String(suffix.result);
            console.log(
              "adding suffix to end",
              suffix.result,
              payload[suffix.key]
            );
          }
        }
      }

      let data = false;
      if (this.isNew) {
        data = await this.createItem(payload);
      } else {
        await this.updateItem(this.item.id, payload);
        data = this.item;
      }
      this.isLoading = false;
      if (this.error) return;

      // here we clear the store cache, to force other components
      // to refetch data after somethign has been modified or added
      store.clearByPath();
      this.$emit("finished", data);
    }
  }
};
</script>

<template>
  <v-container :id="`form_${_uid}`" v-if="!renderDelay">
    <v-alert
      type="error"
      v-if="error"
      class="border border-2 border-red-700 p-2"
      dismissable
      prominent
    >
      <h6 class="text-h6" :id="`${path}_error`">
        Error:
      </h6>
      {{ error }}
    </v-alert>
    <div v-if="isLoading || !editing">
      <v-card class="pa-4">
        <v-progress-linear
          striped
          stream
          rounded
          indeterminate
        ></v-progress-linear>
      </v-card>
    </div>
    <div v-else :key="refreshDefaults">
      <!-- place all unnamed passed children above the table, but below the header -->
      <slot></slot>
      <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
        <v-row>
          <v-col :cols="columns === 1 ? 12 : 6" class="pt-0">
            <v-card class="">
              <div class="pa-4 mt-4 pt-8">
                <div
                  v-for="(att, key) in schema.properties"
                  :key="`${key}_input`"
                >
                  <div>
                    <v-expand-transition>
                      <DynamicInput
                        v-model="editing[key]"
                        :attKey="key"
                        :path="path"
                        :item="editing"
                        @setlistselectitem="setItem"
                        v-show="invisible && !invisible.includes(key)"
                        :prefix="validAdjustment(validPrefixes, key)"
                        :suffix="validAdjustment(validSuffixes, key)"
                        :readOnly="validAdjustment(readOnly, key)"
                      />
                    </v-expand-transition>
                  </div>
                </div>
                <div class="pa-4 my-2 d-flex">
                  <v-btn
                    color="warning"
                    class=""
                    v-if="!isNew"
                    @click="showDelete = !showDelete"
                  >
                    <v-icon class="mr-2">mdi-trash-can</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    type="submit"
                    @click.prevent="$refs.form.validate()"
                    muted
                  >
                    <v-icon>mdi-check-underline-circle</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-auto"
                    @click="() => (showMetaOverride = true)"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                  <v-btn type="submit" color="success">
                    {{ isNew ? "Create" : "Update" }}
                  </v-btn>
                </div>
                <!-- Delete Overlay  -->
                <v-overlay v-model="showDelete" transition="slide-x-transition">
                  <v-card class="pa-8" outlined style="max-width: 800px;">
                    <div>
                      <div>
                        <v-btn
                          block
                          color="success"
                          class="mx-auto my-2"
                          @click="showDelete = false"
                        >
                          <v-icon class="mr-2 pa-4">mdi-arrow-left</v-icon>
                          Whoops Take Me Back
                        </v-btn>
                      </div>
                      <v-divider class="pa-4"></v-divider>
                      <v-alert type="warning" outlined>
                        <div>Confirm Deletion</div>
                      </v-alert>
                      <p>
                        This will also <strong>attempt</strong> to delete any
                        Netbox objects directly accociated with this item.
                      </p>
                      <p>
                        If there are other Netbox objects created other than the
                        primary, these will be <strong>orphaned</strong> in
                        Netbox.
                      </p>
                      <p>
                        YOU are responsible for following this up.
                      </p>
                      <v-divider class="pa-4"></v-divider>
                      <div>
                        <v-btn
                          block
                          class="mx-auto pa-4"
                          color="error"
                          @click="() => deleteItem()"
                        >
                          <v-icon class="mr-2">mdi-trash-can</v-icon>
                          I ACCEPT ALL RESPONSIBILITY AND WISH TO DELETE THIS
                          ITEM
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-overlay>
              </div>
            </v-card>
            <template v-if="foreignKeys.length">
              <div class="my-4">
                <h4 class="mb-2 mt-4 ">Parent Objects</h4>
                <v-expansion-panels>
                  <template v-for="f in foreignKeys">
                    <v-expansion-panel :disabled="!item[f.path]" :key="f.id">
                      <v-expansion-panel-header>
                        <div>
                          <span style="width: 100px;" class=" mr-auto">
                            {{
                              String(f.path)
                                .replaceAll("_", " ")
                                .toUpperCase()
                            }}
                            :
                          </span>
                          {{ item[f.path] ? item[f.path].repr : "Not Set" }}
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-btn
                          :href="`/v2/${f.path}/${item[f.key]}/view`"
                          color="primary"
                          block
                          class="mt-2"
                          append-icon="mdi-open-in-new"
                          v-show="item[f.path]"
                        >
                          Edit
                          <v-icon class="ml-4">mdi-open-in-new</v-icon>
                        </v-btn>
                        <v-simple-table>
                          <tbody>
                            <template v-for="(att, key) in item[f.path]">
                              <tr
                                :key="`${key}_schema`"
                                v-if="
                                  !foreignKeys.some(f => f.key == key) &&
                                    item[key]
                                "
                              >
                                <td class="text-right">
                                  {{
                                    String(key)
                                      .replaceAll("_", " ")
                                      .toUpperCase()
                                  }}
                                </td>
                                <td class="text-left font-weight-bold">
                                  {{ item[f.path][key] }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>

                <div class="py-4"></div>
              </div>
            </template>
          </v-col>
          <v-col :cols="columns === 1 ? 12 : 6">

            <!-- First Column -->
            <div v-if="children && children.length" class="mb-1">
              <!-- Section for isDirect=true -->
              <div v-if="directChildrenItems.length">
                <div
                  class="mb-2"
                  v-for="{
                    key,
                    path: childPath,
                    items: childItems
                  } in directChildrenItems"
                  :key="`direct_child_${key}`"
                >
                  <v-btn
                    right
                    success
                    class="float-right"
                    :href="`/v2/${path}/${id}/${childPath}/new`"
                  >
                    <v-icon color="success" class="mr-2">mdi-plus</v-icon>
                    Add New
                  </v-btn>
                  <strong class="text-uppercase">{{ childPath }}</strong>
                  <small
                    ><p>
                      Child {{ childPath }} objects managed via a
                      {{ path }} parent.
                    </p></small
                  >

                  <div
                    v-for="line in childItems"
                    :key="`${childPath}_${line.id}`"
                  >
                    <v-btn
                      variant="outline"
                      :href="`/v2/${path}/${id}/${childPath}/${line.id}`"
                      block
                      class="mb-2 text-left"
                      :title="`Open ${childPath} #${line.id}`"
                    >
                      <v-icon
                        class="pa-2"
                        :style="[{ color: models[childPath]?.color || '#000' }]"
                        >{{
                          models[childPath]?.icon || "mdi-text-box-outline"
                        }}</v-icon
                      >
                      {{ line.repr }}
                      <div class="w-4 mx-2 ml-auto">
                        {{ childPath }} | #{{ line.id }}
                      </div>
                      <v-icon right class="">mdi-open-in-new</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- Section for isDirect=flse -->
              <div v-if="indirectChildrenItems.length" class="mt-10 mb-10">
                <h4>Linked child objects</h4>
                <div
                  class="mb-2"
                  v-for="{
                    key,
                    path: childPath,
                    items: childItems
                  } in indirectChildrenItems"
                  :key="`direct_child_${key}`"
                >
                  <div
                    v-for="line in childItems"
                    :key="`${childPath}_${line.id}`"
                  >
                    <v-btn
                      variant="outline"
                      :href="`/v2/${childPath}/${line.id}`"
                      block
                      class="mb-2 text-left"
                      :title="`Open ${childPath} #${line.id}`"
                    >
                      <v-icon
                        class="pa-2"
                        :style="[{ color: models[childPath]?.color || '#000' }]"
                        >{{
                          models[childPath]?.icon || "mdi-text-box-outline"
                        }}</v-icon
                      >
                      {{ line.repr }}
                      <div class="w-4 mx-2 ml-auto">
                        {{ childPath }} | #{{ line.id }}
                      </div>
                      <v-icon right class="">mdi-open-in-new</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <!-- end of column 1 -->
            <div class="mt-1 mb-4">
              <v-alert
                v-if="'netbox_id' in item && !item.netbox_id"
                type="warning"
                prominent
                dismissable
              >
                Netbox ID Not Set: issue when creating this Presto Object.
              </v-alert>
              <v-card v-if="'netbox_id' in schema.properties">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Netbox Data
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        type="number"
                        class="mb-4"
                        v-model="editing.netbox_id"
                        label="Netbox ID"
                        hint="Netbox Ids should be set automatically: only edit this if you know what you are doing"
                        persistent-hint
                      >
                        <v-icon slot="prepend"> mdi-network </v-icon>
                      </v-text-field>
                      <pre v-if="item && 'netbox_data' in item">{{ JSON.stringify(item.netbox_data, null, 2) }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <!-- Show dev-stuff for debugging -->

        <template v-if="showMeta || showMetaOverride">
          <v-divider class="my-4"></v-divider>
          <h3>Meta</h3>
          <p>
            This is metadata for debugging and investigating errors. You can
            ignore it unless you are trying to figure something out.
          </p>

          <v-card class="my-4">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Raw Item Data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(item, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Raw Editing Data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(editing, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="schema">
                <v-expansion-panel-header>
                  Request Schema
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(schema, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="responseSchema">
                <v-expansion-panel-header>
                  Response Schema
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(responseSchema, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Read Only Attributes
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(att, key) in readOnlySchemas" :key="key">
                          <td>{{ key }}</td>
                          <td>{{ item[key] }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Foreign Key Relationships
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>KEY</td>
                          <td>PATH</td>
                        </tr>
                        <tr v-for="fk in foreignKeys" :key="fk.path">
                          <td>{{ fk.key }}</td>
                          <td>{{ fk.path }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Suffixes
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(validSuffixes, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Prefixes
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(validPrefixes, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Preset Parent Data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(itemsFromParents, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Parents
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(parents, null, 2) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </template>
      </v-form>
    </div>
  </v-container>
</template>

<style scoped>
.v-overlay__content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
</style>
