<template>
  <v-app-bar flat>
    <v-toolbar-title>Headends</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-icon @click="reloadHeadends">mdi-refresh</v-icon>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeadendsBar",
  methods: {
    ...mapActions(["fetchHeadends"]),
    reloadHeadends: async function() {
      this.$store.commit("isLoading", true);
      await this.fetchHeadends();
      this.$store.commit("isLoading", false);
    }
  }
};
</script>

<style></style>
