<script>
import withSchemas from "./withSchemas.vue";
import withItems from "./withItems.vue";
import Form from "./Forms/Form.vue";

const props = {
  path: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: false
  },
  parents: {
    type: Object,
    required: false,
    default: () => ({})
  }
};

export default {
  props,
  components: {
    withSchemas,
    withItems,
    Form
  },
  data() {
    return {
      error: false,
      formIsValid: false,
      isNew: false,
      flicker: 0,
      configPaths: ["device", "static_service", "subscriber_service", "ipsec"] // TODO: modve this to adjustments.js
    };
  },
  computed: {
    listUrl() {
      return `/v2/${this.path}/`;
    }
  },
  methods: {
    hasConfig() {
      return this.path && !!this.configPaths.includes(this.path);
    },
    onFinished(data) {
      this.flicker++; // this forces the form to reload whenever a change is made, refreshing all data
      const { id } = data;
      if (!id) {
        console.error("No id in returned Form data");
        return;
      }
      // if we just created an object, navigate to it
      // bot ONLY if its not a child or other editing interface
      if ( this.isNew ) {
        const url = this.$router.currentRoute.path && this.$router.currentRoute.path.replace('/new', `/${id}`)
        console.log(`moving to ${url} after item creation`, url );
        this.$router.push(url);
      }
    },
    onDeleted() {
      const url = this.listUrl;
      console.log(`moving to ${url}`);
      this.$router.push(url);
    }
  },
  beforeMount() {
    this.isNew = !!this.id && this.id == "new";
    //console.log("isNew", this.isNew);
  }
};
</script>

<template>
  <withSchemas>
      <!-- Generic Header for all paths -->
          <v-toolbar>
        <v-toolbar-title>
          <div v-if="isNew">Add a new {{ path.toUpperCase() }}</div>
          <div v-else>Edit {{ path.toUpperCase() }} / {{ id }}</div>
        </v-toolbar-title>
        <div class="flex-grow-1">
          <!-- Spacer to right align button below -->
        </div>
        <router-link class="mx-2" :to="listUrl">
          <v-btn color="">
            <v-icon class="mr-4 ">mdi-arrow-up</v-icon>
            View ALL {{ path.toUpperCase() }} ITEMS
          </v-btn>
        </router-link>
        <router-link
          class="mx-2"
          :to="`/v2/${path}/${id}/config`"
          v-if="hasConfig && !isNew"
        >
          <v-btn color="success">
            <v-icon class="">mdi-cog</v-icon>
            View Config
          </v-btn>
        </router-link>
      </v-toolbar>
      <v-container :key="flicker">
      <slot name="before_form"></slot>
      <!-- Supply a blank item for new entries -->
      <template v-if="isNew">
        <Form
          :path="path"
          :id="isNew ? '' : String(id)"
          :item="{}"
          @finished="data => onFinished(data)"
          :showMeta="true"
          :parents="parents"
        ></Form>
      </template>

      <template v-else>
        <withItems :path="path" :id="id" v-slot="{ item }">
          <Form
            :path="path"
            :id="String(id)"
            :item="item"
            :showMeta="true"
            @finished="data => onFinished(data)"
            @deleted="() => onDeleted()"
            :parents="parents"
          ></Form>
        </withItems>
      </template>
    </v-container>
    <slot name="after_form"></slot>
  </withSchemas>
</template>
