
import { Component, Vue } from "vue-property-decorator";
import Pppoe from "@/components/Pppoe/Pppoe.vue";

@Component({
  components: {
    Pppoe
  }
})
export default class Home extends Vue {}
