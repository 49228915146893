
import Vue from "vue";
import {
  AddInterfaceDialog,
  DeviceInterface,
  DeviceType,
  Pppoe,
  Tail
} from "@/model";

export default Vue.extend({
  data: () => ({
    valid: false,
    model: {} as DeviceInterface,
    interfaceType: [
      { txt: "Tail", val: "tail" },
      { txt: "PPPoE", val: "pppoe" },
      { txt: "LAN", val: "lan" }
    ],
    tails: [] as Tail[],
    pppoes: [] as Pppoe[],
    deviceType: {} as DeviceType,
    isAdmin: false,
    showDialog: false,
    resolve: null
  }),
  created() {
    this.clearModel();
  },
  methods: {
    async show(opts: AddInterfaceDialog) {
      this.model = opts.deviceInterface;
      this.tails = opts.tails;
      this.pppoes = opts.pppoes;
      this.deviceType = opts.deviceType;
      this.isAdmin = opts.isAdmin;

      this.model.name =
        this.model.name.split(".").length > 1
          ? this.model.name.split(".")[0]
          : this.model.name;

      return new Promise(r => {
        this.resolve = r;
        this.showDialog = true;
      });
    },
    onOkButtonClick() {
      (this.$refs.addInterfaceForm as Vue & {
        validate: () => boolean;
      }).validate();
      if (this.valid) {
        this.showDialog = false;
        this.resolve && this.resolve(this.model);
        this.clearModel();
      }
    },
    onCancelButtonClick() {
      this.showDialog = false;
      this.resolve && this.resolve(null);
      this.clearModel();
    },
    clearModel() {
      this.model = {
        id: null,
        uuid: "",
        name: "",
        description: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        netbox_id: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        device_id: null,
        device: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        interface_type: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        ip_address: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        vlan_id: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        pppoe_id: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        pppoe: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        pppoe_underlying: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        tail_id: null,
        tail: null,
        vrrp: false,
        // eslint-disable-next-line @typescript-eslint/camelcase
        vrrp_address: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        vrrp_group: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp: false,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_option: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_option_type: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_option_string: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_dns1: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_dns2: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_low: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        dhcp_high: ""
      };
      if (this.$refs.addInterfaceForm) {
        (this.$refs.addInterfaceForm as Vue & {
          resetValidation: () => boolean;
        }).resetValidation();
      }
    }
  }
});
