
import Vue from "vue";
import router from "@/router";
import _ from "lodash";
import { mapGetters } from "vuex";
import { Vlan, Site } from "../../model";
import Alert from "@/components/Alert.vue";

export default Vue.extend({
  name: "Vlan",
  components: {
    Alert
  },
  computed: {
    ...mapGetters([
      "getVlan",
      "isAdmin",
      "getAlert",
      "getVlans",
      "getCustomer",
      "getCustomers",
      "getSites",
      "getHeadends"
    ]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    vlanId(): number {
      return +this.$route.params.id;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: async function() {
    if (!this.getSites.length) await this.$store.dispatch("fetchSites");
    if (!this.getHeadends.length) await this.$store.dispatch("fetchHeadends");
    this.model = { ...this.getVlan(this.vlanId) };

    this.filterSites();

    if (!this.model.site_id && this.model.site?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.site_id = this.model.site.id;
    }

    if (!this.model.headend_id && this.model.headend?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.headend_id = this.model.headend.id;
    }
  },
  watch: {
    getVlans() {
      this.model = { ...this.getVlan(this.vlanId) };

      if (!this.model.site_id && this.model.site?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.site_id = this.model.site.id;
      }

      if (!this.model.headend_id && this.model.headend?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.headend_id = this.model.headend.id;
      }

      this.filterSites();
    },
    getSites() {
      this.filterSites();
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getVlan(this.vlanId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Vlan(),
    sites: [] as Site[]
  }),
  methods: {
    submit() {
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Vlan();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("saveVlan", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            this.$store
              .dispatch("deployVlan", reduced)
              .then(() => {
                this.isLoading = false;
                this.submitButton = "success";

                if (this.isNew) {
                  router.push({
                    path: "/vlan/" + reduced.id,
                    query: { submitButton: this.submitButton }
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.isLoading = false;
                this.submitButton = "error";
              });
          })
          .catch(err => {
            console.log(err);
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deleteVlan() {
      if (confirm("Are you sure you want to delete this Vlan?")) {
        if (this.model.id) {
          const reduced = new Vlan();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deleteVlan", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/vlans"
              });
            })
            .catch(err => {
              console.log(err);
              this.isLoading = false;
            });
        }
      }
    },
    nameRules() {
      const rules = [];

      rules.push(
        (v: string) =>
          /^[a-zA-Z0-9/-]*$/.test(v) ||
          "Name must only contain alphanumeric characters"
      );

      rules.push(
        (v: string) => /^[A-Z0-9/-]*$/.test(v) || "Name must be upper case"
      );

      rules.push((v: string) => !!v || "Name is required");

      rules.push(() => !this.isDuplicate("name") || "Name is not unique");

      return rules;
    },
    isDuplicate(keyName: string) {
      const x = this.getVlans.filter((item: Vlan) => {
        return item.id != this.vlanId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    },
    customerChange() {
      this.filterSites();
    },
    filterSites() {
      this.sites = this.getSites;
      const customerId = this.model.site.customer_id;
      if (customerId) {
        this.sites = this.getSites.filter(
          (site: Site) => site.customer?.id === customerId
        );
      }
    },
    siteChange(e: number) {
      this.model.name = this.getSites.find((site: Site) => site.id === e).name;
    }
  }
});
