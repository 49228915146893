<script>
import Vue from "vue";

import withItems from "../withItems.vue";
import Form from "../Forms/Form.vue";
import ListSelect from "../Forms/ListSelect.vue";
import store from "../simpleStore.js";

export default {
  components: {
    withItems,
    Form,
    ListSelect
  },
  props: {
    step: {
      type: Object,
      required: true,
      default: () => ({
        path: "",
        id: false,
        instructions: "",
        selectFilters: []
      })
    },
    index: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      selected: false,
      kicker: 0,
      parentKicker: 0
    };
  },
  computed: {
    parents() {
      const kicker = store.pageMetaKicker;
      const parents =
        store &&
        store.pageMeta &&
        store.pageMeta.parents &&
        store.pageMeta.parents;
      return parents || {};
    }
  },
  methods: {
    setItem(payload) {
      const { path, key, item } = payload;
      this.saveToMeta(item);
      this.kicker++;
    },
    saveToMeta(obj) {
      // this saves the result of a step selection to the page meta in the store
      // so we can get the values in other components
      // without having to pass it AAAAAALL the way down
      if (!("parents" in store.pageMeta)) {
        store.pageMeta.parents = {};
      }
      console.log("saving step result to store page meta");
      Vue.set(store.pageMeta.parents, this.step.path, obj);
      store.pageMetaKicker++;
    },
    getStepFilters() {
      const validFilters =
        this.step &&
        this.step.selectFilters &&
        this.step.selectFilters.filter(s => !!s.id);
      // map the valid filter ids to the filter format for getSelectItems
      return (
        validFilters &&
        validFilters
          .map(s => ({ [s.targetAttr]: s.id }))
          .reduce((s, o) => ({ ...s, ...o }), {})
      );
    }
  }
};
</script>
<template>
  <div>
    <v-alert type="info" v-if="!step.id" outlined>
      <strong>Step {{ index }}:</strong>
      {{ step.instructions }}
    </v-alert>
    <withItems v-else :path="step.path" :id="String(step.id)" v-slot="{ item }">
      <v-alert type="success">
        <div class="d-flex">
          <div class="">
            <strong>Step {{ index }}:</strong> Selected
            {{ step.path.toUpperCase() }}: {{ item.repr }}
          </div>
          <v-btn class="ml-auto" size="x-small" @click="step.id = null">
            <v-icon height="10px">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </withItems>

    <v-row class="my2 pa-4" v-show="!step.id">
      <v-col>
        <!--  Create an Item -->
        <v-subheader> CREATE NEW {{ step.path.toUpperCase() }} </v-subheader>
        <Form
          :path="step.path"
          id=""
          :item="{}"
          :columns="1"
          :showMeta="false"
          :parents="parents"
          @finished="
            obj => {
              step.id = !!obj.id && obj.id;
              step.obj = !!obj && obj;
              saveToMeta(obj);
            }
          "
        >
        </Form>
      </v-col>
      <!--  SELECT an Item -->
      <v-col>
        <v-subheader>
          <strong class="mr-1">OR </strong> SELECT AN EXISTING
          {{ step.path.toUpperCase() }}
        </v-subheader>
        <v-container>
          <v-card class="pa-4 pt-8">
            <ListSelect
              attKey="id"
              v-model="step.id"
              :path="step.path"
              :filters="getStepFilters()"
              @setlistselectitem="setItem"
            />

            <!-- filters -->
            <template v-if="step.selectFilters">
              <v-divider class="my-4"></v-divider>
              <div v-for="filter in step.selectFilters" :key="filter.path">
                <ListSelect
                  attKey="id"
                  v-model="filter.id"
                  :path="filter.path"
                  :label="`Filter by ${filter.path.toUpperCase()}`"
                />
              </div>
            </template>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
