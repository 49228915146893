<script>
import Vue from "vue";
import axios from "axios";
import withItems from "../withItems.vue";

const props = {
  path: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
};

export default {
  props,
  components: {
    withItems
  },
  data() {
    return {
      showDetails: false,
      isLoading: {},
      errors: {},
      payloads: {},
      kicker: 0,
      jobId: false,
      checkingConfig: false,
      isLive: false,
      selectedTemplate: '',
    };
  },
  mounted() {
    this.get(this.variableUrl);
    this.get(this.templateUrl);
    this.get(this.eventUrl);
    this.get(this.templatesUrl).then( () => {
      this.selectDefaultTemplate()
    });
  },
  computed: {
    variableUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${this.id}/config/variables/`;
    },
    templateUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${this.id}/config/template/`;
    },
    templatesUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/config/templates/`;
    },
    pushUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${this.id}/config/push/${this.selectedTemplate}`;
    },
    statusUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}${this.path}/${this.id}/config/status/${this.jobId}/`;
    },
    eventUrl() {
      return `${process.env.VUE_APP_PRESTO_CORE_API}event_log/?key=config&parent_table=${this.path}&parent_id=${this.id}`;
    },
    isCompleteConfig() {
      const kicker = this.kicker;
      return (
        this.payloads &&
        this.statusUrl in this.payloads &&
        this.payloads[this.statusUrl] &&
        "is_completed" in this.payloads[this.statusUrl] &&
        !!this.payloads[this.statusUrl]["is_completed"]
      );
    },
    isSuccessConfig() {
      const kicker = this.kicker;
      return (
        this.payloads &&
        this.statusUrl in this.payloads &&
        this.payloads[this.statusUrl] &&
        "is_successful" in this.payloads[this.statusUrl] &&
        !!this.payloads[this.statusUrl]["is_successful"]
      );
    },
    isTested() {
      return !!this.jobId && this.isSuccessConfig;
    },
    templates() {
      const k = this.kicker // just for reactivity
      return this.payloads[this.templatesUrl] && Object.keys(this.payloads[this.templatesUrl]).length && this.payloads[this.templatesUrl]
    }

  },
  methods: {
    selectDefaultTemplate() {
      // this chooses a default template in the event that one is not already set
      if ( this.selectedTemplate ) {
        console.log('template already selected ')
        return
      }
      if ( ! this.templates ) {
        console.log('templates not found ', this.templates)
        return
      }
      console.log('Selecting template')
      if ( this.templates && this.templates.length ) {
        for ( const t of this.templates ) {
          if ( t == `${this.path}.j2`) {
            console.log("Found default template", t)
            this.selectedTemplate = t
          }
        }
      }
    },
    downloadFile(key) {
      if (
        !this.payloads[this.templateUrl] ||
        !this.payloads[this.templateUrl][this.selectedTemplate] || 
        !this.payloads[this.templateUrl][this.selectedTemplate][key]
      ) {
        console.error('Incorrect Data format')
        return;
      }
      let dataStr = JSON.stringify(this.payloads[this.templateUrl][this.selectedTemplate][key]);
      dataStr = dataStr.replace(/\n/g, "%0D%0A");
      const dataUri = `data:text/txt;charset=utf-8,${encodeURIComponent(
        dataStr
      )}`;
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", dataUri);
      downloadLink.setAttribute(
        "download",
        `${this.path}_${this.id}_config.txt`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    copyToClipBoard(key) {
      if (
        !this.payloads[this.templateUrl] ||
        !this.payloads[this.templateUrl][this.selectedTemplate] || 
        !this.payloads[this.templateUrl][this.selectedTemplate][key]
      ) {
        console.error('Incorrect Data format')
        return;
      }
      const el = document.createElement("textarea");
      el.value = this.payloads[this.templateUrl][this.selectedTemplate][key];
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    viewPreviousEvent(jsonString = "{}") {
      const payload = JSON.parse(jsonString);
      const jobId = payload.awx_job_id || false;
      if (!jobId) {
        console.error("No ID in previous Event", payload);
        return;
      }
      this.jobId = jobId;
      Vue.set(this.payloads, this.statusUrl, { ...payload });
      console.log("set Previous entry: ", payload, jobId);
      this.checkConfig();
    },
    async get(url) {
      try {
        // reset data
        this.isLoading[url] = true;
        this.payloads[url] = false;
        this.errors[url] = false;
        const response = await axios.get(url);
        const fresh = response.data || {};
        Vue.set(this.payloads, url, fresh);
        console.log("Fetched data: ", url, fresh);
      } catch (err) {
        const errString = err?.response?.data || err.toString();
        console.log(errString);
        this.payloads[url] = {};
        this.errors[url] = errString;
      }
      this.isLoading[url] = false;
      this.kicker++;
    },
    async sendConfig() {
      this.clearConfigPayload();
      const url = this.pushUrl;
      const commit = !!this.isLive;
      try {
        // reset data
        this.checkingConfig = true; // just for frontend niceness
        this.isLoading[url] = true;
        this.payloads[url] = false;
        this.errors[url] = false;
        const response = await axios.post(url, { commit });
        const fresh = response.data || {};
        this.payloads[url] = fresh;
        if ("awx_job_id" in fresh) {
          this.jobId = fresh.awx_job_id;
        }
      } catch (err) {
        const errString = err?.response?.data || err.toString();
        console.log(errString);
        this.payloads[url] = {};
        this.errors[url] = errString;
      }
      this.isLoading[url] = false;
      this.kicker++;
      this.checkConfig();
    },
    async checkConfig(id = false) {
      if (!id) {
        id = this.jobId;
      }
      if (!id) {
        return;
      }
      this.checkingConfig = true;
      await this.get(this.statusUrl);
      if (this.isCompleteConfig) {
        this.get(this.eventUrl);
        this.checkingConfig = false;
      } else {
        setTimeout(() => this.checkConfig(), 1000);
      }
    },
    clearConfigPayload() {
      this.payloads[this.statusUrl] = false;
      this.jobId = false;
    }
  }
};
</script>

<template>
  <div>
  <!-- Generic Header for all paths -->
    <v-toolbar>
      <v-toolbar-title>
        {{ path.toUpperCase() }} CONFIG OPTIONS
        
      </v-toolbar-title>
    </v-toolbar>

    <v-container>
      <div>
        <v-alert type="warning" v-if="!selectedTemplate" > 
          You must select a template to review, check and commit config.
        </v-alert>
        <div>
          <div>
            Selected Template {{ selectedTemplate }}
          </div>
          <v-btn class="pa-4 ma-2" color="success" 
            v-for="t in templates" 
            @click="selectedTemplate = t"
            :outlined="selectedTemplate == t"
            :prepend-icon="t == selectedTemplate ? 'mdi-open-in-new': 'mdi-plus'">
            {{ t }}
          </v-btn>
        </div>
      </div>

    <div v-if="selectedTemplate" >
      <v-card class="pa-4 my-4">
        <div class="d-flex">
          <div>
            <h4>Push Config to Devices</h4>
            <div>
              This pushes presto generated config to Ansible, which does a 'commit
              check', which returns a diff<br />
              Once checked, you can commit config to the device.
            </div>
          </div>

          <div class="ml-auto">
            <v-btn
              :disabled="!isTested"
              @click="isLive = !isLive"
              :color="isLive ? '' : 'info'"
              class="text-h6 pa-8 mr-4 ml-auto"
            >
              <v-icon class="mr-2">{{
                isLive ? "mdi-lock-open" : "mdi-lock"
              }}</v-icon>
              {{ isLive ? "CANCEL" : "COMMIT" }}
            </v-btn>
            <v-btn
              class="text-h6 mr-4 pa-8"
              :color="isLive ? 'warning' : ''"
              @click="sendConfig()"
              :disabled="checkingConfig || !!isLoading[pushUrl]"
            >
              <v-icon class="mr-2">{{
                isLive ? "mdi-alert-circle-outline" : "mdi-vector-difference-ab"
              }}</v-icon>
              <span v-if="checkingConfig">
                <v-icon class="spinning">mdi-sync</v-icon>
              </span>
              <span v-else>
                {{ isLive ? "COMMIT" : "CHECK" }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-card>

      <v-expand-transition>
        <div v-show="jobId" class="mb-4">
          <v-card class="m-2 pa-4 ml-auto overflow-y-auto" outlined>
            <div v-if="!!isLoading[pushUrl]" class="pa-4">
              <v-progress-linear
                striped
                stream
                rounded
                indeterminate
              ></v-progress-linear>
            </div>
            <div v-else-if="!!errors[pushUrl]" class="pa-4">
              <v-alert
                type="error"
                class="border border-2 border-red-700 p-2"
                dismissable
                prominent
              >
                <h6 class="text-h6">
                  Error Pushing Data:
                </h6>
                {{ errors[pushUrl] }}
              </v-alert>
            </div>
            <div v-else class="pa-4">
              <div class="d-flex">
                <div class="mr-2">
                  <v-chip> Job ID: {{ jobId }} </v-chip>
                </div>
                <div>
                    <v-chip v-if="payloads[pushUrl]?.commit">COMMIT</v-chip>
                    <v-chip v-else>COMMIT CHECK</v-chip>
                </div>
                <div class="ml-auto">
                  <!-- <v-btn @click="() => checkConfig()" :disabled="checkingConfig">
                    Manually Check Config Status
                  </v-btn> -->
                  <v-btn
                    color="error"
                    @click="() => clearConfigPayload()"
                    class="mx-2"
                  >
                    <v-icon class="mr-2">mdi-close</v-icon>
                    Close
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="!!errors[statusUrl]" class="pa-4">
              <v-alert
                type="error"
                class="border border-2 border-red-700 p-2"
                dismissable
                prominent
              >
                <h6 class="text-h6">
                  Error Checking Config:
                </h6>
                {{ errors[statusUrl] }}
              </v-alert>
            </div>
            <div
              v-else-if="checkingConfig || !!isLoading[statusUrl]"
              class="pa-4"
            >
              <v-progress-linear
                striped
                stream
                rounded
                indeterminate
              ></v-progress-linear>
            </div>

            <div class="pa-4" v-if="jobId">
              <div class="d-flex mb-4">
                <v-chip
                  :color="isCompleteConfig ? 'success' : 'error'"
                  class="mr-2"
                >
                  <v-icon v-if="checkingConfig" class="mr-2 spinning"
                    >mdi-sync</v-icon
                  >
                  <v-icon v-else-if="isCompleteConfig" class="mr-2"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else class="mr-2">mdi-close</v-icon>
                  COMPLETE
                </v-chip>
                <v-chip :color="isSuccessConfig ? 'success' : 'error'">
                  <v-icon v-if="checkingConfig" class="mr-2 spinning"
                    >mdi-sync</v-icon
                  >
                  <v-icon v-else-if="isSuccessConfig" class="mr-2"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else class="mr-2">mdi-close</v-icon>
                  SUCCESSFUL
                </v-chip>
              </div>
              <v-expand-transition>
                <pre
                  v-show="
                    checkingConfig &&
                      payloads[statusUrl] &&
                      payloads[statusUrl]['result_stdout']
                  "
                  class="stdout"
                  >{{
                    payloads[statusUrl] && payloads[statusUrl]["result_stdout"]
                  }}</pre
                >
              </v-expand-transition>
              <div v-if="payloads[statusUrl] && payloads[statusUrl]['results']">
                <div
                  v-for="result in payloads[statusUrl]['results']"
                  :key="result.host"
                >
                  <v-alert
                    :type="result.is_successful ? 'success' : 'error'"
                    class="mb-0 rounded-b-0"
                  >
                    <div>
                      <v-chip v-if="result.changed" class="float-right">
                        <v-icon class="mr-2"> mdi-alert-box </v-icon>
                        CONFIG CHANGES
                      </v-chip>
                      <v-chip v-else class="float-right">
                        <v-icon class="mr-2"> mdi-close </v-icon>
                        NO CONFIG CHANGES
                      </v-chip>
                      HOST: {{ result.host }}
                    </div>
                  </v-alert>
                  <pre
                    class="stdout mb-4 rounded-t-0"
                    v-if="result.diff"
                  >DIFF<hr/>{{ result.diff && result.diff.prepared }}</pre>
                </div>
              </div>
              <div class="my-4">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Job Output
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="pa-4">
                        <pre class="stdout">{{
                          payloads[statusUrl] &&
                            payloads[statusUrl]["result_stdout"]
                        }}</pre>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="payloads[statusUrl] && payloads[statusUrl]['results']"
                  >
                    <v-expansion-panel-header>
                      Event Results
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="pa-4">
                        <pre class="pa-6 stdout">{{
                          JSON.stringify(payloads[statusUrl]["results"], null, 2)
                        }}</pre>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="payloads[statusUrl] && payloads[statusUrl]['events']"
                    outlined
                  >
                    <v-expansion-panel-header>
                      All Events
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="pa-4">
                        <pre class="pa-6 stdout">{{
                          JSON.stringify(payloads[statusUrl]["events"], null, 2)
                        }}</pre>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-card>
        </div>
      </v-expand-transition>

      <v-divider class="pa-4 my-4"></v-divider>

      <v-row :key="`${kicker}`">
        <v-col cols="12">
          <!-- Template View -->
          <!-- lists multiple configs if there are some  -->

          <v-card class="pa-4 my-4">
            <div v-if="!!isLoading[templateUrl]" class="pa-4">
              <v-progress-linear
                striped
                stream
                rounded
                indeterminate
              ></v-progress-linear>
            </div>

            <div v-else-if="!!errors[templateUrl]" class="pa-4">
              <v-alert
                type="error"
                class="border border-2 border-red-700 p-2"
                dismissable
                prominent
              >
                <h6 class="text-h6">
                  Error Fetching Data:
                </h6>
                {{ errors[templateUrl] }}
              </v-alert>
            </div>

            <div v-else class="pa-4">
              <div v-for="(config, key) in payloads[templateUrl][selectedTemplate]" :key="key">
                <div class="d-flex mb-4">
                  <h6 class="text-h6 ">
                    Config Generated: {{ key.toUpperCase() }}
                  </h6>
                  <div class="flex-grow-1">
                    <!-- Spacer to right align button below -->
                  </div>
                  <v-btn
                    @click="() => downloadFile(key)"
                    class="mx-4"
                    color="info"
                  >
                    <v-icon class="mr-2">mdi-floppy</v-icon>
                    Save to File
                  </v-btn>
                  <v-btn
                    @click="() => copyToClipBoard(key)"
                    class="mx-4"
                    color="info"
                  >
                    <v-icon class="mr-2">mdi-clipboard</v-icon>
                    Copy to Clipboard
                  </v-btn>
                </div>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      View Raw Config
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <pre
                        style="font-family: monospace; color: white; background-color: black; white-space: pre-wrap;"
                        class="pa-6"
                        >{{ payloads[templateUrl][selectedTemplate][key] }}</pre
                      >
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <!-- Variables View -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                View Config Variables
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="!!isLoading[variableUrl]" class="pa-4">
                  <v-progress-linear
                    striped
                    stream
                    rounded
                    indeterminate
                  ></v-progress-linear>
                </div>
                <div v-else-if="!!errors[variableUrl]" class="pa-4">
                  <v-alert
                    type="error"
                    class="border border-2 border-red-700 p-2"
                    dismissable
                    prominent
                  >
                    <h6 class="text-h6">
                      Error Fetching Data:
                    </h6>
                    {{ errors[variableUrl] }}
                  </v-alert>
                </div>

                <div v-else class="pa-4">
                  <pre class="pa-6 stdout">{{
                    JSON.stringify(payloads[variableUrl], null, 2)
                  }}</pre>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="6">
          <withItems :path="path" :id="String(id)" v-slot="{ item }">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  View {{ path.toUpperCase() }} Item Details
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-4">
                    <pre class="pa-6 stdout">{{
                      JSON.stringify(item, null, 2)
                    }}</pre>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </withItems>
        </v-col>
      </v-row>
      
      <v-expansion-panels class="my-4" v-if="payloads[eventUrl]">
        <v-expansion-panel>
          <v-expansion-panel-header>
            View Previous Push Runs
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(event, index) in payloads[eventUrl]"
                    :key="`${index}_previeous_run`"
                  >
                    <td>#{{ event.id }}</td>
                    <td>{{ event.created_on }}</td>
                    <td>{{ event.created_by }}</td>
                    <td>
                      <v-btn @click="viewPreviousEvent(event.value)">
                        View Details
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    </v-container>
   
  </div>
</template>

<style>
.stdout {
  font-size: 80%;
  font-family: monospace;
  color: white;
  background-color: black;
  white-space: pre-wrap;
  line-height: 1;
  white-space: pre-wrap;
  border: 2px solid #4caf50;
  border-radius: 5px;
  padding: 10px;
}
.v-icon.spinning {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
