<template>
  <v-app-bar flat>
    <v-toolbar-title>IPSECs</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-icon @click="reloadIpsecs">mdi-refresh</v-icon>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "IpsecsBar",
  methods: {
    ...mapActions(["fetchIpsecs"]),
    reloadIpsecs: async function() {
      this.$store.commit("isLoading", true);
      await this.fetchIpsecs();
      this.$store.commit("isLoading", false);
    }
  }
};
</script>

<style></style>
