
import { Component, Vue } from "vue-property-decorator";
import PppoesBar from "@/components/Pppoe/PppoesBar.vue";
import PppoesList from "@/components/Pppoe/PppoesList.vue"; // @ is an alias to /src

@Component({
  components: {
    PppoesBar,
    PppoesList
  }
})
export default class Home extends Vue {}
