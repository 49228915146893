
import { Component, Vue } from "vue-property-decorator";
import Region from "@/components/Region/Region.vue";

@Component({
  components: {
    Region
  }
})
export default class Home extends Vue {}
