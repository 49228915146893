<script>
import store from "./simpleStore";

export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    removeFlash(index) {
      store.removeFlash(index);
    }
  },
  computed: {
    hasFlashes() {
      return store.flashes && Object.keys(store.flashes).length > 0;
    },
    flashes() {
      return this.hasFlashes && store.flashes;
    }
  }
};
</script>

<template>
  <v-snackbar
    v-if="hasFlashes"
    bottom
    right
    :value="show"
    :timeout="-1"
    class="pa-2"
    id="flashes"
  >
    <div v-for="(flash, index) in flashes" :key="index">
        <v-alert
          :type="Array.isArray(flash) ? flash[0] : ''"
          dismissable
          dense
        >
          {{ Array.isArray(flash) ? flash[1] : flash }}
          <v-btn x-small plain @click="removeFlash(index)" class="float-right">
            <div>X</div>
          </v-btn>
        </v-alert>
    </div>
  </v-snackbar>
</template>

