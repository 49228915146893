<script>
import List from "../List.vue";

export default {
  components: {
    List
  }
};
</script>

<template>
  <div>
    <v-toolbar>
      <div class="">
        <h4>Search Static and Subscription Services</h4>
      </div>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col :cols="6">
          <List path="static_service" />
        </v-col>
        <v-col :cols="6">
          <List path="subscriber_service" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
