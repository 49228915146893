import Vue from "vue";
import Vuex from "vuex";
import Vuetify from "../plugins/vuetify";
import Partners from "./modules/Partners";
import Customers from "./modules/Customers";
import Sites from "./modules/Sites";
import Vlans from "./modules/Vlans";
import Vrfs from "./modules/Vrfs";
import Tails from "./modules/Tails";
import Pppoes from "./modules/Pppoes";
import Ipsecs from "./modules/Ipsecs";
import Devices from "./modules/Devices";
import Regions from "./modules/Regions";
import Headends from "./modules/Headends";
import Login from "./modules/Login";
import DeviceTypes from "./modules/DeviceTypes";
import Alert from "./modules/Alert";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    nightSwitch(_, nightSwitch) {
      Vuetify.framework.theme.dark = nightSwitch;
    },
    isLoading(state, loading) {
      state.isLoading = loading;
    }
  },
  modules: {
    Login,
    Partners,
    Customers,
    Sites,
    Vlans,
    Vrfs,
    Tails,
    Pppoes,
    Ipsecs,
    Devices,
    DeviceTypes,
    Regions,
    Headends,
    Alert
  },
  plugins: []
});
