<script>
import withSchemas from "../withSchemas.vue";
import Step from "./_Step.vue";
import store from "../simpleStore.js";

export default {
  components: {
    withSchemas,
    Step
  },
  data() {
    return {
      choice: false,
      choices: [
        {
          display: "NEBS VLAN",
          description: "A VLAN for a TC4 or non EE link",
          path: "nebs_vlan",
          icon: "mdi-link"
        },
        {
          display: "EE VLAN",
          description: "NBN EE services need their own type of VLAN",
          path: "ee_vlan",
          icon: "mdi-server"
        }
      ],
      steps: {
        partner: {
          path: "partner",
          id: false,
          instructions: "Create a new Partner or select one."
        },
        customer: {
          path: "customer",
          id: false,
          instructions:
            "Create a new Customer or select one. You can filter the list of customers by Partner. Partners can only be added by an adminstrator.",
          selectFilters: [
            {
              id: false,
              path: "partner",
              target: "customer",
              targetAttr: "partner_id"
            }
          ]
        },
        site: {
          path: "site",
          id: false,
          instructions:
            "Create a new Site or select one. The SLUG must match the customer slug"
        },
        nebsVlan: {
          path: "nebs_vlan",
          id: false,
          instructions:
            "Create a new NEBS VLAN. If you can select one you are done. This is mutually exclusive with EE"
        },
        eeVlan: {
          path: "ee_vlan",
          id: false,
          instructions:
            "Create a new EE VLAN or select one. If you can select one you are done. This is mutually exclusive with NEBS"
        }
      }
    };
  },
  computed: {
    stepIds(){
      return this.steps &&
        this.steps
          .filter(s => !!s.id && !!s.path && !!s.data)
          .map( s => s.id)
    },
    filters() {
      const validFilters = this.steps && this.steps.filter(s => !!s.id);
      // map the valid filter ids to the filter format for getSelectItems
      return (
        validFilters &&
        validFilters
          .map(s => ({ [s.targetAttr]: s.id }))
          .reduce((s, o) => ({ ...s, ...o }), {})
      );
    }
  }
};
</script>

<template>
  <withSchemas>
    <!-- Generic Header for all paths -->
    <v-toolbar>
      <div class="">
        <h4>Create a New Customer, Site or VLAN</h4>
      </div>
    </v-toolbar>

    <v-container>
      <Step
        :step="steps['customer']"
        @done="data => (steps['customer'].data = data)"
        index="1"
      />
      <Step
        v-if="!!steps['customer'].id"
        :step="steps['site']"
        @done="data => (steps['site'].data = data)"
        index="2"
      />
      <div v-show="!!steps['site'].id">
        <v-alert type="info" v-if="!choice" transition="slide-y-transition">
          Choose your VLAN type
        </v-alert>
        <v-row class="w-100 my-4">
          <v-col v-for="ch in choices" :key="ch.path">
            <v-btn
              @click="choice = ch"
              outlined
              block
              class="pa-12 my-6 w-100"
              :class="choice.path == ch.path && 'success'"
            >
              <div>
                <div>
                  <v-icon>{{ ch.icon }}</v-icon>
                  {{ ch.display }}
                </div>
                <small>
                  {{ ch.description }}
                </small>
              </div>
            </v-btn>
          </v-col>
        </v-row>

        <Step
          v-if="choice.path == 'nebs_vlan'"
          :step="steps['nebsVlan']"
          @done="data => (steps['nebsVlan'].data = data)"
          index="3"
          transition="slide-x-transition"
        />
        <Step
          v-if="choice.path == 'ee_vlan'"
          :step="steps['eeVlan']"
          @done="(path, data) => (steps['eeVlan'].data = data)"
          index="3"
        />
      </div>
      <v-alert
        v-if="!!steps['eeVlan'].id || !!steps['nebsVlan'].id"
        type="success"
        transition="slide-y-transition"
      >
        Success! Your VLAN has been created. Create a Service next.
        <!-- TODO: add links -->
      </v-alert>
    </v-container>
  </withSchemas>
</template>
