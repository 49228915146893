import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Partners from "../views/Partner/Partners.vue";
import Partner from "../views/Partner/Partner.vue";
import Customers from "../views/Customer/Customers.vue";
import Customer from "../views/Customer/Customer.vue";
import Sites from "../views/Site/Sites.vue";
import Site from "../views/Site/Site.vue";
import Vlans from "../views/Vlan/Vlans.vue";
import Vlan from "../views/Vlan/Vlan.vue";
import Vrfs from "../views/Vrf/Vrfs.vue";
import Vrf from "../views/Vrf/Vrf.vue";
import Tails from "../views/Tail/Tails.vue";
import Tail from "../views/Tail/Tail.vue";
import Pppoes from "../views/Pppoe/Pppoes.vue";
import Pppoe from "../views/Pppoe/Pppoe.vue";
import Ipsecs from "../views/Ipsec/Ipsecs.vue";
import Ipsec from "../views/Ipsec/Ipsec.vue";
import Devices from "../views/Device/Devices.vue";
import Device from "../views/Device/Device.vue";
import Regions from "../views/Region/Regions.vue";
import Region from "../views/Region/Region.vue";
import Headends from "../views/Headend/Headends.vue";
import Headend from "../views/Headend/Headend.vue";
import store from "../store";

// Presto 2 Stuff
import List from "../components/Core/List.vue";
import Item from "../components/Core/Item.vue";
import Edit from "../components/Core/ItemEdit.vue";
import ItemParentEdit from "../components/Core/ItemParentEdit.vue";
import SiteWizard from "../components/Core/Wizards/Site.vue";
import SubscriberServiceWizard from "../components/Core/Wizards/SubscriberService.vue";
import StaticServiceWizard from "../components/Core/Wizards/StaticService.vue";
import DeviceWizard from "../components/Core/Wizards/Device.vue";
import AllServices from "../components/Core/Pages/Services.vue";
import ConfigTemplates from "../components/Core/Pages/Config.vue";

import coreStore from "../components/Core/simpleStore.js"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/v2/:path/:id/config",
    name: "Config Templates",
    component: ConfigTemplates,
    props: true
  },
  {
    name: "List",
    // a dynamic path that gets the path and shows a list of records
    path: "/v2/:path",
    component: List,
    props: true
  },
  {
    name: "View",
    // a dynamic path that gets the he path and ID, and shows a form based
    // on the item schema ( assuming it exists )
    path: "/v2/:path/:id/view",
    component: Edit,
    props: true
  },
  {
    name: "Edit",
    // a dynamic path that gets the he path and ID, and shows a form based
    // on the item schema ( assuming it exists )
    path: "/v2/:path/:id",
    component: Edit,
    props: true
  },
  {
    name: "EditChild",
    // a dynamic path that gets the he path and ID, and shows a form based
    // on the item schema ( assuming it exists )
    path: "/v2/:parentPath/:parentId/:path/:id",
    component: ItemParentEdit,
    props: true
  },
  {
    path: "/forms/sites",
    name: "Site Form",
    component: SiteWizard
  },
  {
    path: "/forms/staticservice",
    name: "Static Service Form",
    component: StaticServiceWizard
  },
  {
    path: "/forms/subscriberservice",
    name: "Subscriber Service Form",
    component: SubscriberServiceWizard
  },
  {
    path: "/forms/device",
    name: "Device Form",
    component: DeviceWizard
  },
  {
    path: "/all/services",
    name: "List all Services",
    component: AllServices
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners
  },
  {
    path: "/partner/:id?",
    name: "Partner",
    component: Partner
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers
  },
  {
    path: "/customer/:id?",
    name: "Customer",
    component: Customer
  },
  {
    path: "/sites",
    name: "Sites",
    component: Sites
  },
  {
    path: "/site/:id?",
    name: "Site",
    component: Site
  },
  {
    path: "/vlans",
    name: "Vlans",
    component: Vlans
  },
  {
    path: "/vlan/:id?",
    name: "Vlan",
    component: Vlan
  },
  {
    path: "/vrfs",
    name: "Vrfs",
    component: Vrfs
  },
  {
    path: "/vrf/:id?",
    name: "Vrf",
    component: Vrf
  },
  {
    path: "/tails",
    name: "Tails",
    component: Tails
  },
  {
    path: "/tail/:id?",
    name: "Tail",
    component: Tail
  },
  {
    path: "/pppoes",
    name: "Pppoes",
    component: Pppoes
  },
  {
    path: "/pppoe/:id?",
    name: "Pppoe",
    component: Pppoe
  },
  {
    path: "/ipsecs",
    name: "Ipsecs",
    component: Ipsecs
  },
  {
    path: "/ipsec/:id?",
    name: "Ipsec",
    component: Ipsec
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices
  },
  {
    path: "/device/:id?",
    name: "Device",
    component: Device
  },
  {
    path: "/regions",
    name: "Regions",
    component: Regions
  },
  {
    path: "/region/:id?",
    name: "Region",
    component: Region
  },
  {
    path: "/headends",
    name: "Headends",
    component: Headends
  },
  {
    path: "/headend/:id?",
    name: "Headend",
    component: Headend
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresLogin: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("../components/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.getters.isLoggedIn) next({ name: "Login" });
  // this ensures that page metadata is reset between every page
  coreStore.resetPageMeta();
  next();
});

export default router;
