<template>
  <v-btn class="float-right" @click="window.FreshworksWidget('open')">
    <v-icon class="float-right">mdi-bug</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      window: document.defaultView
    }
  },
  computed: {
    ...mapGetters(["getUsername" ])
  },
  mounted() {
    const FWScript = document.createElement("script");
    FWScript.setAttribute(
      "src",
      "https://aus-widget.freshworks.com/widgets/51000003080.js"
    );
    document.head.appendChild(FWScript);
  },
  created() {
    const window = this.window
    window.fwSettings = {
      widget_id: 51000003080
    };
    !(function() {
      if ("function" != typeof window.FreshworksWidget) {
        const n = function() {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
    window.FreshworksWidget("hide", "launcher");

    const name = this.getUsername
    const emails = {
      'encoo': "admin@encoo.com.au",
      'andre': "andre@encoo.com.au",
      'craig': "croberton@encoo.com.au",
      'kevin': "admin@encoo.com.au",
      'jli@encoo.com.au': "jli@encoo.com.au",
      'jnjeru@encoo.com.au': "jnjeru@encoo.com.au",
      'skozak@encoo.com.au': "skozak@encoo.com.au",
      'rtiwana': "rtiwana@encoo.com.au",
      'ash': "awatt@encoo.com.au",
      'avithanage': "avithanage@encoo.com.au",
      'connect': "app@encoo.com.au",
      'tmuller': "tmuller@encoo.com.au",
      'hlwin': "hlwin@encoo.com.au",
    }
    const email = ( name in emails ) ? emails[name]: "admin@encoo.com.au"
    window.FreshworksWidget("identify", "ticketForm", {
      name,
      email
    });
    window.FreshworksWidget("prefill", "ticketForm", {
      status: "2", // Open
      group_id: 51000313735, // DEV group
      custom_fields: {
        cf_app_project: "Presto"
      }
    });
    window.FreshworksWidget("hide", "ticketForm", [
      "status",
      "name",
      "email",
      "group_id",
      "custom_fields.cf_app_project"
    ]);
  }
};
</script>
