
import { Component, Vue } from "vue-property-decorator";
import VlansBar from "@/components/Vlan/VlansBar.vue";
import VlansList from "@/components/Vlan/VlansList.vue"; // @ is an alias to /src

@Component({
  components: {
    VlansBar,
    VlansList
  }
})
export default class Home extends Vue {}
