
import { Component, Vue } from "vue-property-decorator";
import VrfsBar from "@/components/Vrf/VrfsBar.vue";
import VrfsList from "@/components/Vrf/VrfsList.vue"; // @ is an alias to /src

@Component({
  components: {
    VrfsBar,
    VrfsList
  }
})
export default class Home extends Vue {}
