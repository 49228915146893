import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Alert as AlertModel } from "../../model";

@Module
export default class Alert extends VuexModule {
  alert = null as AlertModel;

  @Mutation
  resetAlert() {
    this.alert = null;
  }

  @Mutation
  setAlert(alert: AlertModel) {
    this.alert = alert;
  }

  get getAlert(): AlertModel {
    return this.alert;
  }
}
