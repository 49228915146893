
import { Component, Vue } from "vue-property-decorator";
import RegionsBar from "@/components/Region/RegionsBar.vue";
import RegionsList from "@/components/Region/RegionsList.vue"; // @ is an alias to /src

@Component({
  components: {
    RegionsBar,
    RegionsList
  }
})
export default class Home extends Vue {}
