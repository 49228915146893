import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { DeviceType } from "../../model";

@Module
export default class DeviceTypes extends VuexModule {
  deviceTypes = Array<DeviceType>();

  @Mutation
  setDeviceTypes(deviceTypes: [DeviceType]) {
    this.deviceTypes = deviceTypes;
  }

  @Mutation
  updateDeviceType(deviceType: DeviceType) {
    if (this.deviceTypes.find(x => x.id === deviceType.id)) {
      this.deviceTypes = _.map(this.deviceTypes, function(a) {
        return a.id === deviceType.id ? deviceType : a;
      });
    } else {
      this.deviceTypes.push(deviceType);
    }
  }

  @Mutation
  removeDeviceType(deviceType: DeviceType) {
    _.remove(this.deviceTypes, {
      id: deviceType.id
    });
  }

  @Action
  fetchDeviceTypes() {
    return (
      axios
        .get(process.env.VUE_APP_PRESTO_API + "device_type/")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("fetchDeviceTypes");
          this.context.commit("setDeviceTypes", resp.data);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
        })
    );
  }

  @Action
  fetchDeviceType(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "device_type/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchDeviceType: " + id);
        this.context.commit("updateDeviceType", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveDeviceType(deviceType: DeviceType) {
    deviceType = _.pickBy(deviceType, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as DeviceType;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "device_type/";
    if (deviceType.id) {
      method = "put";
      url += deviceType.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: deviceType })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveDeviceType");
          if (!deviceType.id) {
            deviceType.id = resp.data.id;
          }
          this.context.dispatch("fetchDeviceType", deviceType.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployDeviceType(deviceType: DeviceType) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_PRESTO_API +
            "device_type/" +
            deviceType.id +
            "/deploy"
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployDeviceType");
          this.context.dispatch("fetchDeviceType", deviceType.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteDeviceType(deviceType: DeviceType) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "device_type/" +
            deviceType.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteDeviceType");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeDeviceType", deviceType);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getDeviceTypes() {
    return this.deviceTypes.sort((t1, t2) => {
      const name1 = t1.device_type.toLowerCase();
      const name2 = t2.device_type.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getDeviceType() {
    return (id: number) => {
      const ret = this.deviceTypes.find(x => x.id === id) as DeviceType;
      return ret ? ret : new DeviceType();
    };
  }
}
