import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Partner } from "../../model";

@Module
export default class Partners extends VuexModule {
  partners = Array<Partner>();

  @Mutation
  setPartners(partners: [Partner]) {
    this.partners = partners;
  }

  @Mutation
  updatePartner(partner: Partner) {
    if (this.partners.find(x => x.id === partner.id)) {
      this.partners = _.map(this.partners, function(a) {
        return a.id === partner.id ? partner : a;
      });
    } else {
      this.partners.push(partner);
    }
  }

  @Mutation
  removePartner(partner: Partner) {
    _.remove(this.partners, {
      id: partner.id
    });
  }

  @Action
  fetchPartners() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "partner/")
      .then((resp: AxiosResponse) => {
        console.log("fetchPartners");
        this.context.commit("setPartners", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchPartner(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "partner/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchPartner: " + id);
        this.context.commit("updatePartner", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  savePartner(partner: Partner) {
    partner = _.pickBy(partner, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Partner;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "partner/";
    if (partner.id) {
      method = "put";
      url += partner.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: partner })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("savePartner");
          if (!partner.id) {
            partner.id = resp.data.id;
          }
          this.context.dispatch("fetchPartner", partner.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployPartner(partner: Partner) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_PRESTO_API +
            "partner/" +
            partner.id +
            "/deploy"
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployPartner");
          // eslint-disable-next-line @typescript-eslint/camelcase
          partner.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchPartner", partner.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deletePartner(partner: Partner) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "partner/" +
            partner.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deletePartner");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removePartner", partner);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getPartners() {
    return this.partners.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getPartner() {
    return (id: number) => {
      const ret = this.partners.find(x => x.id === id) as Partner;
      return ret ? ret : new Partner();
    };
  }
}
