import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Site } from "../../model";

@Module
export default class Sites extends VuexModule {
  sites = Array<Site>();

  @Mutation
  setSites(sites: [Site]) {
    this.sites = sites;
  }

  @Mutation
  updateSite(site: Site) {
    if (this.sites.find(x => x.id === site.id)) {
      this.sites = _.map(this.sites, function(a) {
        return a.id === site.id ? site : a;
      });
    } else {
      this.sites.push(site);
    }
  }

  @Mutation
  removeSite(site: Site) {
    _.remove(this.sites, {
      id: site.id
    });
  }

  @Action
  fetchSites() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "site/")
      .then((resp: AxiosResponse) => {
        console.log("fetchSites");
        this.context.commit("setSites", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchSite(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "site/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchSite: " + id);
        this.context.commit("updateSite", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveSite(site: Site) {
    site = _.pickBy(site, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Site;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "site/";
    if (site.id) {
      method = "put";
      url += site.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: site })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveSite");
          if (!site.id) {
            site.id = resp.data.id;
          }
          this.context.dispatch("fetchSite", site.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deploySite(site: Site) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "site/" + site.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deploySite");
          // eslint-disable-next-line @typescript-eslint/camelcase
          site.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchSite", site.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteSite(site: Site) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "site/" + site.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteSite");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeSite", site);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getSites() {
    return this.sites.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getSite() {
    return (id: number) => {
      const ret = this.sites.find(x => x.id === id) as Site;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.customer_id) ret.customer_id = ret.customer.id;
      return ret ? ret : new Site();
    };
  }
}
