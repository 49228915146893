
import { Component, Vue } from "vue-property-decorator";
import Site from "@/components/Site/Site.vue";

@Component({
  components: {
    Site
  }
})
export default class Home extends Vue {}
