
import Vue from "vue";
import router from "@/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  AddInterfaceDialog,
  Device,
  DeviceInterface,
  Ipsec,
  Tail,
  Pppoe,
  Site,
  DeviceType,
  PushDeviceConfigRequest
} from "../../model";
import AddInterface from "@/components/AddInterface.vue";
import Alert from "@/components/Alert.vue";
import PushDeviceConfig from "../PushDeviceConfig.vue";

export default Vue.extend({
  name: "Device",
  components: {
    Alert,
    AddInterface,
    PushDeviceConfig
  },
  computed: {
    ...mapGetters([
      "getDevice",
      "isAdmin",
      "getAlert",
      "getDevices",
      "getDeviceTypes",
      "getCustomer",
      "getCustomers",
      "getSites",
      "getIpsecs",
      "getIpsecs",
      "getTails",
      "getPppoes"
    ]),
    isNew(): boolean {
      return this.$route.params.id == undefined;
    },
    deviceId(): number {
      return +this.$route.params.id;
    },
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  created: async function() {
    this.model = { ...this.getDevice(this.deviceId) };

    this.filterSites();
    this.filterIpsecs();
    this.filterIpsecs();
    this.filterTails();
    this.filterPppoes();
    this.filterDeviceType();
    this.getDeviceInterfaces();

    if (!this.model.site_id && this.model.site?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.site_id = this.model.site.id;
    }

    if (!this.model.ipsec_id && this.model.ipsec?.id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.model.ipsec_id = this.model.ipsec.id;
    }
  },
  watch: {
    getDevices() {
      this.model = { ...this.getDevice(this.deviceId) };

      if (!this.model.site_id && this.model.site?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.site_id = this.model.site.id;
      }

      if (!this.model.ipsec_id && this.model.ipsec?.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.model.ipsec_id = this.model.ipsec.id;
      }

      this.filterSites();
      this.filterIpsecs();
      this.filterTails();
      this.filterPppoes();
      this.filterDeviceType();
      this.getDeviceInterfaces();
    },
    getSites() {
      this.filterSites();
      this.filterIpsecs();
      this.filterTails();
      this.filterPppoes();
    },
    // model: {
    //   deep: true,
    //   handler() {
    //     // A little hack to get the button to stay green after the model is updated
    //     if (this.modelUpdated) {
    //       this.submitButton = "";
    //     }
    //     this.modelUpdated = true;
    //   }
    // },
    "$route.params.id": function() {
      (this.$refs.form as Vue & {
        resetValidation: () => boolean;
      }).resetValidation();
      this.model = { ...this.getDevice(this.deviceId) };
      this.submitButton = this.$route.query.submitButton as string;
    }
  },
  data: () => ({
    valid: false,
    submitButton: "",
    deployButton: "warning",
    modelUpdated: false,
    model: new Device(),
    sites: [] as Site[],
    ipsecs: [] as Ipsec[],
    tails: [] as Tail[],
    pppoes: [] as Pppoe[],
    deviceType: {} as DeviceType,
    deviceInterfaces: [] as DeviceInterface[]
  }),
  methods: {
    submit() {
      this.submitButton = "";
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (this.valid) {
        const reduced = new Device();
        _.assign(reduced, _.pick(this.model, _.keys(reduced)));
        this.isLoading = true;
        this.$store
          .dispatch("saveDevice", reduced)
          .then(resp => {
            this.modelUpdated = false;
            reduced.id = reduced.id ? reduced.id : resp.data.id;

            if (this.deviceInterfaces.length) {
              this.$store
                .dispatch("deployDevice", reduced)
                .then(() => {
                  this.isLoading = false;
                  this.submitButton = "success";

                  if (this.isNew) {
                    router.push({
                      path: "/device/" + reduced.id,
                      query: { submitButton: this.submitButton }
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                  this.isLoading = false;
                  this.submitButton = "error";
                });
            } else if (!this.deviceInterfaces.length && this.model.netbox_id) {
              this.isLoading = false;
              this.submitButton = "error";
            } else if (!this.deviceInterfaces.length) {
              this.isLoading = false;
              this.submitButton = "success";

              if (this.isNew) {
                router.push({
                  path: "/device/" + reduced.id,
                  query: { submitButton: this.submitButton }
                });
              }
            }
          })
          .catch(err => {
            console.log(err);
            this.submitButton = "error";
            this.isLoading = false;
          });
      } else {
        this.submitButton = "error";
      }
    },
    deleteDevice() {
      if (this.model.id) {
        if (confirm("Are you sure you want to delete this device?")) {
          const reduced = new Device();
          _.assign(reduced, _.pick(this.model, _.keys(reduced)));
          this.isLoading = true;
          this.$store
            .dispatch("deleteDevice", reduced)
            .then(() => {
              this.isLoading = false;
              router.push({
                path: "/devices"
              });
            })
            .catch(err => {
              console.log(err);
              this.isLoading = false;
            });
        }
      }
    },
    getPppoe(id: number) {
      return this.pppoes.find((pppoe: Pppoe) => pppoe.id === id);
    },
    getDeviceInterfaces() {
      if (this.model.id) {
        axios
          .get(
            process.env.VUE_APP_PRESTO_API +
              "device/" +
              this.model.id +
              "/interfaces"
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((resp: AxiosResponse<any>) => {
            console.log("fetchDeviceInterfaces");
            this.deviceInterfaces = resp.data as DeviceInterface[];
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    getDeviceInterface(id: number) {
      return this.deviceInterfaces.find(
        (deviceInterface: DeviceInterface) => deviceInterface.id === id
      );
    },
    async addDeviceInterface(id = 0) {
      const deviceInterface = await (this.$refs.addInterfaceDialog as Vue & {
        show: (opts: AddInterfaceDialog) => DeviceInterface;
      }).show({
        deviceInterface: this.getDeviceInterface(id)
          ? this.getDeviceInterface(id)
          : new DeviceInterface(),
        tails: this.tails,
        pppoes: this.pppoes,
        deviceType: this.deviceType,
        isAdmin: this.isAdmin
      });

      if (deviceInterface && this.model.id) {
        if (deviceInterface.interface_type == "pppoe") {
          const pppoe = this.getPppoe(deviceInterface.pppoe_id);
          if (
            pppoe.service_type == "fttp" ||
            pppoe.service_type == "nwas" ||
            pppoe.service_type == "fttc" ||
            pppoe.service_type == "hfc"
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            deviceInterface.pppoe_underlying = deviceInterface.name + ".0";
          } else {
            // eslint-disable-next-line @typescript-eslint/camelcase
            deviceInterface.pppoe_underlying = undefined;
          }
          deviceInterface.name = this.deviceType.pppoe_interface;
        } else {
          deviceInterface.name =
            deviceInterface.name + "." + deviceInterface.vlan_id;
        }

        const method = deviceInterface.id ? "put" : "post";
        const url = deviceInterface.id
          ? process.env.VUE_APP_PRESTO_API +
            "device/interfaces/" +
            deviceInterface.id
          : process.env.VUE_APP_PRESTO_API +
            "device/" +
            this.model.id +
            "/interfaces";

        axios({ method, url, data: deviceInterface })
          .then(() => {
            console.log("addDeviceInterface");
            this.getDeviceInterfaces();
            this.submitButton = "";
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.submitButton = "error";
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    async pushDeviceConfig() {
      await (this.$refs.pushDeviceConfigDialog as Vue & {
        show: (opts: PushDeviceConfigRequest) => void;
      }).show({
        // eslint-disable-next-line @typescript-eslint/camelcase
        device_id: this.model.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        console_port: 0,
        shutdown: false
      });
    },
    deleteDeviceInterface(interfaceId: number) {
      if (confirm("Are you sure you want to delete interface " + interfaceId)) {
        console.log("deleteDeviceInterface", interfaceId);

        axios
          .delete(
            process.env.VUE_APP_PRESTO_API +
              "device/interfaces/" +
              interfaceId +
              "?netbox=true"
          )
          .then(() => {
            this.deviceInterfaces = this.deviceInterfaces.filter(
              x => x.id !== interfaceId
            );
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: AxiosError<any>) => {
            this.$store.commit("setAlert", {
              title: "Error",
              text: err.response.data.message,
              color: "error"
            });
          });
      }
    },
    nameRules() {
      const rules = [];

      rules.push(
        (v: string) =>
          /^[a-zA-Z0-9/-]*$/.test(v) ||
          "Name must only contain alphanumeric characters"
      );

      rules.push(
        (v: string) => /^[A-Z0-9/-]*$/.test(v) || "Name must be upper case"
      );

      rules.push((v: string) => !!v || "Name is required");

      rules.push(() => !this.isDuplicate("name") || "Name is not unique");

      return rules;
    },
    isDuplicate(keyName: string) {
      const x = this.getDevices.filter((item: Device) => {
        return item.id != this.deviceId;
      });

      const uniqueSearch = _.pick(this.model, [keyName]);
      return _.find(x, uniqueSearch);
    },
    customerChange() {
      this.filterSites();
      this.filterIpsecs();
      this.filterTails();
      this.filterPppoes();
    },
    siteChange(e: number) {
      this.model.name = this.getSites.find((site: Site) => site.id === e).name;
      this.filterIpsecs();
      this.filterTails();
      this.filterPppoes();
    },
    deviceTypeChange() {
      this.filterDeviceType();
    },
    filterSites() {
      const customerId = this.model.site.customer.id;
      if (customerId) {
        this.sites = this.getSites.filter(
          (site: Site) => site.customer?.id === customerId
        );
      } else {
        this.sites = this.getSites;
      }
    },
    filterIpsecs() {
      const customerId = this.model.site.customer.id;
      const siteId = this.model.site.id;
      if (customerId) {
        this.ipsecs = this.getIpsecs.filter(
          (ipsec: Ipsec) =>
            ipsec.site.id === siteId && ipsec.site.customer?.id === customerId
        );
      } else {
        this.ipsecs = this.getIpsecs;
      }
    },
    async filterTails() {
      const customerId = this.model.site.customer.id;
      const siteId = this.model.site.id;
      if (customerId) {
        this.tails = await this.getTails.filter(
          (tail: Tail) =>
            tail.vlan.site.id === siteId &&
            tail.vlan.site.customer?.id === customerId
        );
      } else {
        this.tails = this.getTails;
      }
    },
    filterPppoes() {
      const customerId = this.model.site.customer.id;
      const siteId = this.model.site.id;
      if (customerId) {
        this.pppoes = this.getPppoes.filter(
          (pppoe: Pppoe) =>
            pppoe.site.id === siteId && pppoe.site.customer?.id === customerId
        );
      } else {
        this.pppoes = this.getPppoes;
      }
    },
    filterDeviceType() {
      if (this.model.device_type_id) {
        this.deviceType = this.getDeviceTypes.find(
          (deviceType: DeviceType) =>
            deviceType.id === this.model.device_type_id
        );
      }
    }
  }
});
