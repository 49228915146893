
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: ["color", "title", "text"],
  computed: {
    ...mapGetters(["getAlert"]),
    alert: {
      get() {
        return this.getAlert;
      },
      set(value) {
        this.$store.commit("setAlert", value);
      }
    }
  },

  data: () => ({
    dialog: false
  })
});
