import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Pppoe } from "../../model";

@Module
export default class Pppoes extends VuexModule {
  pppoes = Array<Pppoe>();

  @Mutation
  setPppoes(pppoes: [Pppoe]) {
    this.pppoes = pppoes;
  }

  @Mutation
  updatePppoe(pppoe: Pppoe) {
    if (this.pppoes.find(x => x.id === pppoe.id)) {
      this.pppoes = _.map(this.pppoes, function(a) {
        return a.id === pppoe.id ? pppoe : a;
      });
    } else {
      this.pppoes.push(pppoe);
    }
  }

  @Mutation
  removePppoe(pppoe: Pppoe) {
    _.remove(this.pppoes, {
      id: pppoe.id
    });
  }

  @Action
  fetchPppoes() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "pppoe/")
      .then((resp: AxiosResponse) => {
        console.log("fetchPppoes");
        this.context.commit("setPppoes", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchPppoe(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "pppoe/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchPppoe: " + id);
        this.context.commit("updatePppoe", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  savePppoe(pppoe: Pppoe) {
    pppoe = _.pickBy(pppoe, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Pppoe;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "pppoe/";
    if (pppoe.id) {
      method = "put";
      url += pppoe.id;
    }

    return new Promise((resolve, reject) => {
      axios({ method, url, data: pppoe })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("savePppoe");
          if (!pppoe.id) {
            pppoe.id = resp.data.id;
          }
          this.context.dispatch("fetchPppoe", pppoe.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployPppoe(pppoe: Pppoe) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "pppoe/" + pppoe.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployPppoe");
          // eslint-disable-next-line @typescript-eslint/camelcase
          pppoe.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchPppoe", pppoe.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deletePppoe(pppoe: Pppoe) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "pppoe/" + pppoe.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deletePppoe");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removePppoe", pppoe);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getPppoes() {
    return this.pppoes.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getPppoe() {
    return (id: number) => {
      const ret = this.pppoes.find(x => x.id === id) as Pppoe;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.vrf_id) ret.vrf_id = ret.vrf?.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.site_id) ret.site_id = ret.site?.id;
      return ret ? ret : new Pppoe();
    };
  }
}
