import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Device } from "../../model";

@Module
export default class Devices extends VuexModule {
  devices = Array<Device>();

  @Mutation
  setDevices(devices: [Device]) {
    this.devices = devices;
  }

  @Mutation
  updateDevice(device: Device) {
    if (this.devices.find(x => x.id === device.id)) {
      this.devices = _.map(this.devices, function(a) {
        return a.id === device.id ? device : a;
      });
    } else {
      this.devices.push(device);
    }
  }

  @Mutation
  removeDevice(device: Device) {
    _.remove(this.devices, {
      id: device.id
    });
  }

  @Action
  fetchDevices() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "device/")
      .then((resp: AxiosResponse) => {
        console.log("fetchDevices");
        this.context.commit("setDevices", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchDevice(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "device/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchDevice: " + id);
        this.context.commit("updateDevice", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveDevice(device: Device) {
    device = _.pickBy(device, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Device;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "device/";
    if (device.id) {
      method = "put";
      url += device.id;
    }

    return new Promise((resolve, reject) => {
      axios({ method, url, data: device })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveDevice");
          if (!device.id) {
            device.id = resp.data.id;
          }
          this.context.dispatch("fetchDevice", device.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployDevice(device: Device) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "device/" + device.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployDevice");
          // eslint-disable-next-line @typescript-eslint/camelcase
          device.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchDevice", device.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteDevice(device: Device) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "device/" +
            device.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteDevice");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeDevice", device);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getDevices() {
    return this.devices.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getDevice() {
    return (id: number) => {
      const ret = this.devices.find(x => x.id === id) as Device;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.site_id) ret.site_id = ret.site.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.ipsec_id) ret.ipsec_id = ret.ipsec?.id;
      return ret ? ret : new Device();
    };
  }
}
