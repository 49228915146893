import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Headend } from "../../model";

@Module
export default class Headends extends VuexModule {
  headends = Array<Headend>();

  @Mutation
  setHeadends(headends: [Headend]) {
    this.headends = headends;
  }

  @Mutation
  updateHeadend(headend: Headend) {
    if (this.headends.find(x => x.id === headend.id)) {
      this.headends = _.map(this.headends, function(a) {
        return a.id === headend.id ? headend : a;
      });
    } else {
      this.headends.push(headend);
    }
  }

  @Mutation
  removeHeadend(headend: Headend) {
    _.remove(this.headends, {
      id: headend.id
    });
  }

  @Action
  fetchHeadends() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "headend/")
      .then((resp: AxiosResponse) => {
        console.log("fetchHeadends");
        this.context.commit("setHeadends", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchHeadend(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "headend/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchHeadend: " + id);
        this.context.commit("updateHeadend", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveHeadend(headend: Headend) {
    headend = _.pickBy(headend, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Headend;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "headend/";
    if (headend.id) {
      method = "put";
      url += headend.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: headend })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveHeadend");
          if (!headend.id) {
            headend.id = resp.data.id;
          }
          this.context.dispatch("fetchHeadend", headend.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteHeadend(headend: Headend) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API +
            "headend/" +
            headend.id +
            "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteHeadend");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeHeadend", headend);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getHeadends() {
    return this.headends.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getHeadend() {
    return (id: number) => {
      const ret = this.headends.find(x => x.id === id) as Headend;
      return ret ? ret : new Headend();
    };
  }
}
