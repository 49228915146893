import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Tail } from "../../model";

@Module
export default class Tails extends VuexModule {
  tails = Array<Tail>();

  @Mutation
  setTails(tails: [Tail]) {
    this.tails = tails;
  }

  @Mutation
  updateTail(tail: Tail) {
    if (this.tails.find(x => x.id === tail.id)) {
      this.tails = _.map(this.tails, function(a) {
        return a.id === tail.id ? tail : a;
      });
    } else {
      this.tails.push(tail);
    }
  }

  @Mutation
  removeTail(tail: Tail) {
    _.remove(this.tails, {
      id: tail.id
    });
  }

  @Action
  fetchTails() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "tail/")
      .then((resp: AxiosResponse) => {
        console.log("fetchTails");
        this.context.commit("setTails", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchTail(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "tail/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchTail: " + id);
        this.context.commit("updateTail", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveTail(tail: Tail) {
    tail = _.pickBy(tail, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Tail;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "tail/";
    if (tail.id) {
      method = "put";
      url += tail.id;
    }

    return new Promise((resolve, reject) => {
      axios({ method, url, data: tail })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveTail");
          if (!tail.id) {
            tail.id = resp.data.id;
          }
          this.context.dispatch("fetchTail", tail.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployTail(tail: Tail) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "tail/" + tail.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployTail");
          // eslint-disable-next-line @typescript-eslint/camelcase
          tail.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchTail", tail.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteTail(tail: Tail) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "tail/" + tail.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteTail");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeTail", tail);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getTails() {
    return this.tails.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getTail() {
    return (id: number) => {
      const ret = this.tails.find(x => x.id === id) as Tail;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.vlan_id) ret.vlan_id = ret.vlan.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.vrf_id) ret.vrf_id = ret.vrf?.id;
      return ret ? ret : new Tail();
    };
  }
}
