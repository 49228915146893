
import Vue from "vue";
import { PushDeviceConfigRequest } from "@/model";
import axios, { AxiosError } from "axios";
import _ from "lodash";

export default Vue.extend({
  computed: {
    isLoading: {
      get: function() {
        if (this.$store.state.isLoading) {
          return true;
        }
        return false;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    }
  },
  data: () => ({
    valid: false,
    showDialog: false,
    resolve: null,
    consolePortList: _.range(1, 12),
    powerOff: [
      { txt: "No", val: false },
      { txt: "Yes", val: true }
    ],
    pushDeviceConfigRequest: new PushDeviceConfigRequest()
  }),
  methods: {
    async show(opts: PushDeviceConfigRequest) {
      this.pushDeviceConfigRequest = opts;
      return new Promise(r => {
        this.resolve = r;
        this.showDialog = true;
      });
    },
    validateForm() {
      (this.$refs.pushDeviceConfigForm as Vue & {
        validate: () => boolean;
      }).validate();
    },
    onOkButtonClick() {
      this.validateForm();
      if (this.valid) {
        this.pushConfig(this.pushDeviceConfigRequest);
        this.showDialog = false;
        this.resolve && this.resolve(null);
      }
    },
    onCloseButtonClick() {
      this.pushDeviceConfigRequest = new PushDeviceConfigRequest();
      this.showDialog = false;
      this.resolve && this.resolve(null);
    },
    async pushConfig(req: PushDeviceConfigRequest) {
      this.isLoading = true;

      const url =
        process.env.VUE_APP_PRESTO_API +
        "/device/" +
        req.device_id.toString(10) +
        "/configure?console_port=" +
        req.console_port.toString() +
        "&shutdown=" +
        req.shutdown.toString();

      console.log("PushDeviceConfigRequest:", req);

      await axios
        .get(url)
        .then(response => {
          console.log("PushDeviceConfigResponse:", response);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          let errMessage = "";
          if (
            err.response &&
            "data" in err.response &&
            "message" in err.response.data
          ) {
            errMessage = err.response.data.message;
          } else {
            errMessage = "Unknown error";
          }
          this.$store.commit("setAlert", {
            title: "Error",
            text: errMessage,
            color: "error"
          });
        });

      this.isLoading = false;
    }
  }
});
