
import Vue from "vue";
import _ from "lodash";
import { AddPrefixDialog, TailPrefixCreate } from "@/model";

export default Vue.extend({
  data: () => ({
    valid: false,
    model: {} as TailPrefixCreate,
    prefixType: [
      { txt: "Private", val: "private" },
      { txt: "Public", val: "public" }
    ],
    prefixLength: _.range(32, 25),
    showDialog: false,
    resolve: null,
    hideRoute: false,
    disableRoute: false
  }),
  created() {
    this.clearModel();
  },
  methods: {
    async show(opts: AddPrefixDialog) {
      if (opts.hideRoute == true) {
        this.hideRoute = true;
        this.prefixLength = _.range(31, 25);
      }
      if (opts.disableRoute == true) {
        this.disableRoute = true;
      }
      if (opts.route != undefined) {
        this.model.route = opts.route;
      }

      return new Promise(r => {
        this.resolve = r;
        this.showDialog = true;
      });
    },
    validateForm() {
      (this.$refs.addPrefixForm as Vue & {
        validate: () => boolean;
      }).validate();
    },
    onOkButtonClick() {
      this.validateForm();
      if (this.valid) {
        this.showDialog = false;
        this.resolve && this.resolve(this.model);
        this.clearModel();
      }
    },
    onCancelButtonClick() {
      this.showDialog = false;
      this.resolve && this.resolve(null);
      this.clearModel();
    },
    clearModel() {
      this.model = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        prefix_type: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        prefix_length: 0,
        route: false
      };
      if (this.$refs.addPrefixForm) {
        (this.$refs.addPrefixForm as Vue & {
          resetValidation: () => boolean;
        }).resetValidation();
      }
    }
  }
});
