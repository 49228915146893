
import { Component, Vue } from "vue-property-decorator";
import Partner from "@/components/Partner/Partner.vue";

@Component({
  components: {
    Partner
  }
})
export default class Home extends Vue {}
