import Vue from "vue";
import router from "@/router";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosResponse } from "axios";
import { LoginResponse, LoginRequest } from "../../model";

@Module
export default class Login extends VuexModule {
  status = "";
  token = localStorage.getItem("token") || "";
  username = localStorage.getItem("username") || "";
  level = localStorage.getItem("level") || "";

  @Mutation
  authRequest() {
    this.status = "loading";
  }

  @Mutation
  authSuccess(data: LoginResponse) {
    Vue.prototype.$http.defaults.headers.common["X-API-KEY"] = data;
    localStorage.setItem("token", data.token);
    localStorage.setItem("username", data.username);
    localStorage.setItem("level", data.level);
    this.status = "success";
    this.token = data.token;
    this.username = data.username;
    this.level = data.level;
  }

  @Mutation
  authError() {
    localStorage.removeItem("token");
    this.status = "error";
  }

  @Mutation
  resetLogin() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("level");
    this.status = "";
    this.token = "";
  }

  @Action
  login(user: LoginRequest) {
    axios
      .post(process.env.VUE_APP_PRESTO_API + "login/", user)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((resp: AxiosResponse<any>) => {
        this.context.commit("authSuccess", {
          ...resp.data,
          username: user.username
        });
        this.context.dispatch("fetchPartners");
        router.push("/");
      })
      .catch(err => {
        console.log(err);
      });
  }

  @Action({ commit: "resetLogin" })
  logout() {
    return true;
  }

  get isLoggedIn() {
    return this.token;
  }
  get getUsername() {
    return this.username;
  }
  get getLevel() {
    return this.level;
  }
  get isAdmin() {
    return this.getLevel == "admin";
  }
}
