
import Vue from "vue";
import { PushConfigRequest, PushConfigResponse } from "@/model";
import axios, { AxiosError } from "axios";

export default Vue.extend({
  data: () => ({
    valid: false,
    showDialog: false,
    resolve: null,
    panel: { noneExpanded: [], firstExpanded: [0], secondExpanded: [1] },
    pushConfigResponse: [] as PushConfigResponse[],
    pushConfigRequest: new PushConfigRequest()
  }),
  methods: {
    async show(opts: PushConfigRequest) {
      this.pushConfig(opts);
      this.pushConfigRequest = opts;
      return new Promise(r => {
        this.resolve = r;
        this.showDialog = true;
      });
    },
    onOkButtonClick() {
      if (this.pushConfigRequest.test == true) {
        this.pushConfigResponse = [];
        this.pushConfigRequest.test = false;
        this.pushConfig(this.pushConfigRequest);
      }
    },
    onCloseButtonClick() {
      this.pushConfigResponse = [];
      this.pushConfigRequest = new PushConfigRequest();
      this.showDialog = false;
      this.resolve && this.resolve(null);
    },
    pushConfig(req: PushConfigRequest) {
      const url =
        process.env.VUE_APP_PRESTO_API +
        req.objectType +
        "/" +
        req.objectId.toString(10) +
        "/configure?test=" +
        req.test.toString();

      console.log("PushConfigRequest:", req);

      axios
        .get(url)
        .then(response => {
          const parsedResponseArray = [] as PushConfigResponse[];
          if (this.pushConfigRequest.objectType != "tail") {
            const responseData = response.data as
              | {
                  [key: string]: PushConfigResponse;
                }
              | { [key: string]: PushConfigResponse }[];

            if (Array.isArray(responseData)) {
              for (const arrKey in responseData) {
                for (const key in responseData[arrKey]) {
                  if (typeof responseData[arrKey][key] == "object") {
                    const parsedResponse: PushConfigResponse = {
                      ...responseData[arrKey][key],
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      ...{ device_name: key }
                    };
                    parsedResponseArray.push(parsedResponse);
                  }
                }
              }
            } else {
              for (const key in responseData) {
                const parsedResponse: PushConfigResponse = {
                  ...responseData[key],
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  ...{ device_name: key }
                };
                parsedResponseArray.push(parsedResponse);
              }
            }
          } else {
            parsedResponseArray.push(response.data as PushConfigResponse);
          }
          this.pushConfigResponse = parsedResponseArray as PushConfigResponse[];
          console.log("PushConfigResponse:", this.pushConfigResponse);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.$store.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
        });

      // // Debug Code
      // this.pushConfigResponse = [
      //   {
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     device_name: "Device1",
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     loaded_config: "Config",
      //     diff: "",
      //     result: true,
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     already_configured: true,
      //     comment: "Status Response"
      //   },
      //   {
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     device_name: "Device2",
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     loaded_config: "Config",
      //     diff: "Diff",
      //     result: true,
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     already_configured: false,
      //     comment: "Status Response"
      //   }
      // ];
    }
  }
});
