
import { Component, Vue } from "vue-property-decorator";
import Device from "@/components/Device/Device.vue";

@Component({
  components: {
    Device
  }
})
export default class Home extends Vue {}
