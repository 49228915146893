
import { Component, Vue } from "vue-property-decorator";
import Ipsec from "@/components/Ipsec/Ipsec.vue";

@Component({
  components: {
    Ipsec
  }
})
export default class Home extends Vue {}
