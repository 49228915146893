
import Vue from "vue";
import { mapGetters } from "vuex";
import update from "@/mixins/update";
import withSchemas from "./components/Core/withSchemas.vue";
import Flashes from "./components/Core/Flashes.vue";
import FreshDesk from "./components/Core/FreshDesk.vue"

export default Vue.extend({
  components: { withSchemas, Flashes, FreshDesk },
  name: "App",
  mixins: [update],
  computed: {
    ...mapGetters(["isLoggedIn", "getUsername", "getLevel", "isAdmin"]),
    nightSwitch: {
      get: function() {
        return localStorage.getItem("nightSwitch") == "true";
      },
      set: function(value: string) {
        localStorage.setItem("nightSwitch", value);
        this.$store.commit("nightSwitch", value);
      }
    },
    isLoading: {
      get: function() {
        return this.$store.state.isLoading;
      },
      set: function(value: boolean) {
        this.$store.commit("isLoading", value);
      }
    },
    currentRouteName(): string {
      return this.$route.name;
    }
  },
  created: async function() {
    this.$store.commit("nightSwitch", this.nightSwitch);
  },
  watch: {
    $route() {
      // ensure menus all close on exit
      this.resetMenus();
      // other route change stuff: maybe a fancy animation?
    }
  },
  methods: {
    resetMenus() {
      this.v2Admin = false;
    },
    async loadV1() {
      if (this.$refs.prestoV1Menu.isActive) {
        return;
      }

      console.log("Loading V1 data");
      if (this.isLoading) {
        return;
      }
      if (this.isLoaded) {
        return;
      }
      this.isLoading = true;
      const partners = this.$store.dispatch("fetchPartners");
      const customers = this.$store.dispatch("fetchCustomers");
      const sites = this.$store.dispatch("fetchSites");
      const vlans = this.$store.dispatch("fetchVlans");
      const vrfs = this.$store.dispatch("fetchVrfs");
      const tails = this.$store.dispatch("fetchTails");
      const pppoes = this.$store.dispatch("fetchPppoes");
      const ipsecs = this.$store.dispatch("fetchIpsecs");
      const devices = this.$store.dispatch("fetchDevices");
      const deviceTypes = this.$store.dispatch("fetchDeviceTypes");
      await Promise.all([
        partners,
        customers,
        sites,
        vlans,
        vrfs,
        tails,
        pppoes,
        ipsecs,
        devices,
        deviceTypes
      ]);
      this.isLoading = false;
      this.isLoaded = true;
    }
  },
  data: () => ({
    isLoaded: false, // tracks whether the Presto data is full loaded, to prevent overloading
    showByIndex: null,
    overlay: false,
    v2Admin: false,
    wizardMenuItems: [
      {
        title: "VLAN Wizard",
        icon: "mdi-office-building-marker-outline",
        routerLink: "/forms/sites"
      },
      {
        title: "Add Static Service",
        icon: "mdi-link",
        routerLink: "/forms/staticservice"
      },
      {
        title: "Add Subscriber Service",
        icon: "mdi-sync",
        routerLink: "/forms/subscriberservice"
      },
      {
        title: "Add Device",
        icon: "mdi-server",
        routerLink: "/forms/device"
      }
    ],
    coreMenuItems: [
    {
        title: "Partners",
        icon: "mdi-handshake-outline",
        routerLink: "/v2/partner"
      },
      {
        title: "Customers",
        icon: "mdi-account-group-outline",
        routerLink: "/v2/customer"
      },
      {
        title: "VRFs",
        icon: "mdi-cloud-outline",
        routerLink: "/v2/vrf"
      },
      {
        title: "Sites",
        icon: "mdi-office-building-marker-outline",
        routerLink: "/v2/site"
      },
      {
        title: "VLANs",
        icon: "mdi-pipe",
        routerLink: "/v2/vlan"
      },
      {
        title: "EE Vlan",
        icon: "mdi-pipe",
        routerLink: "/v2/ee_vlan"
      },
      {
        title: "NEBS Vlan",
        icon: "mdi-pipe",
        routerLink: "/v2/nebs_vlan"
      },
      {
        title: "Tails",
        icon: "mdi-transit-connection-variant",
        routerLink: "/v2/tail"
      },
      {
        title: "Static Services",
        icon: "mdi-link",
        routerLink: "/v2/static_service"
      },
      {
        title: "Subscriber Services",
        icon: "mdi-sync",
        routerLink: "/v2/subscriber_service"
      },
      {
        title: "IPSecs",
        icon: "mdi-lock",
        routerLink: "/v2/ipsec"
      },
      {
        title: "Devices",
        icon: "mdi-router-network",
        routerLink: "/v2/device"
      },
      {
        title: "NNIs",
        icon: "mdi-connection",
        routerLink: "/v2/nni"
      },
      {
        title: "CVCs",
        icon: "mdi-connection",
        routerLink: "/v2/cvc"
      }
    ],
    menuItems: [
      {
        title: "Partners",
        icon: "mdi-handshake-outline",
        routerLink: "/partners",
        addRouterLink: "/partner"
      },
      {
        title: "Customers",
        icon: "mdi-account-group-outline",
        routerLink: "/customers",
        addRouterLink: "/customer"
      },
      {
        title: "Sites",
        icon: "mdi-office-building-marker-outline",
        routerLink: "/sites",
        addRouterLink: "/site"
      },
      {
        title: "Vlans",
        icon: "mdi-pipe-disconnected",
        routerLink: "/vlans",
        addRouterLink: "/vlan"
      },
      {
        title: "VRFs",
        icon: "mdi-cloud-outline",
        routerLink: "/vrfs",
        addRouterLink: "/vrf"
      },
      {
        title: "Tails",
        icon: "mdi-transit-connection-variant",
        routerLink: "/tails",
        addRouterLink: "/tail"
      },
      {
        title: "PPPoE",
        icon: "mdi-router",
        routerLink: "/pppoes",
        addRouterLink: "/pppoe"
      },
      {
        title: "IPSec",
        icon: "mdi-lock",
        routerLink: "/ipsecs",
        addRouterLink: "/ipsec"
      },
      {
        title: "Devices",
        icon: "mdi-router-network",
        routerLink: "/devices",
        addRouterLink: "/device"
      }
    ],
    menuItemsBottom: [
      {
        title: "V1 Regions",
        icon: "mdi-map-marker",
        routerLink: "/regions",
        addRouterLink: "/region"
      },
      {
        title: "V1 Headends",
        icon: "mdi-connection",
        routerLink: "/headends",
        addRouterLink: "/headend"
      },
      {
        title: "Logout",
        icon: "mdi-logout-variant",
        routerLink: "/login"
      }
    ]
  })
});
