
import { Component, Vue } from "vue-property-decorator";
import CustomersBar from "@/components/Customer/CustomersBar.vue";
import CustomersList from "@/components/Customer/CustomersList.vue"; // @ is an alias to /src

@Component({
  components: {
    CustomersBar,
    CustomersList
  }
})
export default class Home extends Vue {}
