<script>
import store from "./simpleStore";
import withSchemas from "./withSchemas.vue";
import DataTable from "@/components/DataTable.vue";
import WithItems from "./withItems.vue";

const props = {
  path: {
    type: String,
    required: true
  }
};

export default {
  props,
  components: {
    withSchemas,
    DataTable,
    WithItems
  },
  data() {
    return {
      isLoading: false,
      // a magic array denoting which paths use config templates
      // TODO: att this from backend based on the presence of certain
      configPaths: ["device", "static_service", "subscriber_service", "ipsec"]
    };
  },
  computed: {
    schema() {
      return store.schemas && store.schemas[this.path]
        ? store.schemas[this.path]
        : false;
    },
    hasConfig() {
      return this.path && this.configPaths.includes(this.path);
    },
    headers() {
      if (!this.schema) {
        return [];
      }
      const h = [
        {
          text: "# ID",
          align: "start",
          sortable: false,
          value: "id"
        },
        { text: "Name", value: "repr" },
        { text: "Netbox ID", value: "netbox_id" },
        { text: "Actions", align: "end", value: "actions", sortable: false }
      ];

      return h;
    }
  }
};
</script>

<template>
  <withSchemas>
    <WithItems :path="path" :errorOnEmpty="false" v-slot="{ items }">
        <!-- Generic Header for all paths -->
        <v-toolbar>
          <v-toolbar-title>
            <div>ALL {{ `${path}`.toUpperCase() }} ITEMS</div>
          </v-toolbar-title>
          <div class="flex-grow-1">
            <!-- Spacer to right align button below -->
          </div>
          <div class="ml-auto p-2">
            <v-btn :to="`/v2/${path}/new`" color="success">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add New
            </v-btn>
          </div>
        </v-toolbar>
        
      <div class="p-2 container mx-auto h-full">
        <div>
          <!-- place all passed children above the table -->
          <slot></slot>
          <!-- but below the header -->
          <DataTable
            :items="Object.values(items) || []"
            sort-by="id"
            dense="true"
            :headers="headers"
            :itemLinkBase="`/v2/${path}/`"
            :hasConfig="hasConfig"
          />
        </div>
      </div>
    </WithItems>
  </withSchemas>
</template>
