
import { Component, Vue } from "vue-property-decorator";
import Customer from "@/components/Customer/Customer.vue";

@Component({
  components: {
    Customer
  }
})
export default class Home extends Vue {}
