import Vue from "vue";

const store = Vue.observable({
  paths: {},
  schemas: false,
  responseSchemas: false,
  loadingSchema: false,
  loadingPaths: {},
  flashes: {},
  pageMeta: {},
  pageMetaKicker: 0,
  flashTimeout: 20000,
  // generic methods commonly used in components to get the schema
  schemaBypath(path) {
    return this.schemas[path] && this.schemas[path];
  },
  responseSchemaBypath(path) {
    return this.responseSchemas[path] && this.responseSchemas[path];
  },
  getPathFromSchemaProperty(path, key) {
    const schema = this.responseSchemaBypath(path);
    const prop =
      schema && !!(key in schema.properties) && schema.properties[key];
    if (!prop) return false;

    if ("x-path" in prop) {
      return prop["x-path"];
    }
    if (!!("items" in prop) && !!("x-path" in prop.items)) {
      return prop.items["x-path"];
    }
    return false;
  },
  generateForeignKeys(path) {
    // generate an array of attribtes on this form that are foreign keys
    // helper function for other functions
    const schema = this.schemaBypath(path);
    const fks =
      schema &&
      schema.properties &&
      Object.entries(schema.properties)
        .filter(([key, att]) => !!("x-path" in att && att.type == "integer"))
        .map(([key, att]) => ({
          key,
          path: att["x-path"],
          fk: ""
        }));

    return fks && fks.length && fks;
  },
  // helper methods
  addFlash(flash) {
    const index = Date.now();
    Vue.set(this.flashes, index, flash);
    console.log("flash added", index, flash, this.flashes);
    setTimeout(() => this.removeFlash(index), this.flashTimeout);
  },
  removeFlash(index) {
    console.log("removing Flash", index);
    Vue.delete(this.flashes, index);
  },
  getSchema(path, isResponse = false) {
    const target = isResponse ? this.responseSchemas : this.schemas;
    return target && path in target && target[path];
  },
  resetPageMeta() {
    this.pageMeta = {};
  },
  clearByPath(path = false) {
    if (!path) {
      // clear all if path is blank
      this.paths = {};
      return;
    }
    const urlDelete = [];
    for (const url in this.paths) {
      if (String(url).includes(path)) {
        urlDelete.push(url);
      }
    }
    for (const url of urlDelete) {
      console.log("Deleting url from store", url);
      delete this.paths[url];
    }
  }
});

export default store;
