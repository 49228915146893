
import { Component, Vue } from "vue-property-decorator";
import HeadendsBar from "@/components/Headend/HeadendsBar.vue";
import HeadendsList from "@/components/Headend/HeadendsList.vue"; // @ is an alias to /src

@Component({
  components: {
    HeadendsBar,
    HeadendsList
  }
})
export default class Home extends Vue {}
