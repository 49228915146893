import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import _ from "lodash";
import { Vrf } from "../../model";

@Module
export default class Vrfs extends VuexModule {
  vrfs = Array<Vrf>();

  @Mutation
  setVrfs(vrfs: [Vrf]) {
    this.vrfs = vrfs;
  }

  @Mutation
  updateVrf(vrf: Vrf) {
    if (this.vrfs.find(x => x.id === vrf.id)) {
      this.vrfs = _.map(this.vrfs, function(a) {
        return a.id === vrf.id ? vrf : a;
      });
    } else {
      this.vrfs.push(vrf);
    }
  }

  @Mutation
  removeVrf(vrf: Vrf) {
    _.remove(this.vrfs, {
      id: vrf.id
    });
  }

  @Action
  fetchVrfs() {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "vrf/")
      .then((resp: AxiosResponse) => {
        console.log("fetchVrfs");
        this.context.commit("setVrfs", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  fetchVrf(id: number) {
    return axios
      .get(process.env.VUE_APP_PRESTO_API + "vrf/" + id.toString())
      .then((resp: AxiosResponse) => {
        console.log("fetchVrf: " + id);
        this.context.commit("updateVrf", resp.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  }

  @Action
  saveVrf(vrf: Vrf) {
    vrf = _.pickBy(vrf, function(value) {
      return !(value === undefined || value === null || value == "");
    }) as Vrf;

    let method = "post" as Method;
    let url = process.env.VUE_APP_PRESTO_API + "vrf/";
    if (vrf.id) {
      method = "put";
      url += vrf.id;
    }
    return new Promise((resolve, reject) => {
      axios({ method, url, data: vrf })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("saveVrf");
          if (!vrf.id) {
            vrf.id = resp.data.id;
          }
          this.context.dispatch("fetchVrf", vrf.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deployVrf(vrf: Vrf) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_PRESTO_API + "vrf/" + vrf.id + "/deploy")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          console.log("deployVrf");
          // eslint-disable-next-line @typescript-eslint/camelcase
          vrf.netbox_id = resp.data.netbox_id;
          this.context.dispatch("fetchVrf", vrf.id);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  @Action
  deleteVrf(vrf: Vrf) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_PRESTO_API + "vrf/" + vrf.id + "?netbox=true"
        )
        .then((resp: AxiosResponse) => {
          console.log("deleteVrf");
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.context.commit("removeVrf", vrf);
          resolve(resp);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          console.log(err);
          this.context.commit("setAlert", {
            title: "Error",
            text: err.response.data.message,
            color: "error"
          });
          reject(err.response.data.message);
        });
    });
  }

  get getVrfs() {
    return this.vrfs.sort((t1, t2) => {
      const name1 = t1.name.toLowerCase();
      const name2 = t2.name.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
  }

  get getVrf() {
    return (id: number) => {
      const ret = this.vrfs.find(x => x.id === id) as Vrf;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ret && !ret.customer_id) ret.customer_id = ret.customer.id;
      return ret ? ret : new Vrf();
    };
  }
}
