
import { Component, Vue } from "vue-property-decorator";
import IpsecsBar from "@/components/Ipsec/IpsecsBar.vue";
import IpsecsList from "@/components/Ipsec/IpsecsList.vue"; // @ is an alias to /src

@Component({
  components: {
    IpsecsBar,
    IpsecsList
  }
})
export default class Home extends Vue {}
